import React, { useEffect, useState } from 'react'
import {
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Stack,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Badge,
  Select,
} from '@chakra-ui/react'
import { FiSearch, FiPlus, FiTrash } from 'react-icons/fi'
import { useAuth } from '../../hooks/Auth'
import { api } from '../../utils/api'
import { useNavigate } from 'react-router-dom'
import { UploadTemplate } from './UploadTemplate'
import Swal from 'sweetalert2'
import { formatDate } from '../../utils/formatDate'

export interface IProposalTemplate {
  id: string
  name: string
  filename: string
  template_url: string
  size: string
  tenant_id: string
  selected?: boolean
  created_at: string
  updated_at: string
}

export function ProposalsTemplate() {
  const { user } = useAuth()
  const navigate = useNavigate()
  const [proposalTemplates, setProposalTemplates] = useState<
    IProposalTemplate[]
  >([])
  const [proposalTemplatesFilter, setProposalTemplatesFilter] = useState<
    IProposalTemplate[]
  >([])
  const [isOpenCreateNewContact, setIsOpenCreateNewContact] = useState(false)

  useEffect(() => {
    async function getProposalTemplates() {
      const { data } = await api.get<IProposalTemplate[]>(`/proposals/template`)
      const format = data.map((item) => {
        const createdAt = formatDate(item.created_at)

        return {
          ...item,
          created_at: createdAt,
        }
      })
      setProposalTemplates(format)
      setProposalTemplatesFilter(format)
    }
    getProposalTemplates()
  }, [user, navigate])

  function handleFilterWithName(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value) {
      const dataFilter = proposalTemplates.filter((item) => {
        const itemLowerCase = item.name.toLowerCase()
        const valueLowerCase = event.target.value.toLowerCase()

        if (itemLowerCase.includes(valueLowerCase)) return item
        return null
      })
      return setProposalTemplatesFilter(dataFilter)
    }
    setProposalTemplatesFilter(proposalTemplates)
  }

  function onSaveNewTemplate(template: IProposalTemplate) {
    setProposalTemplates((prev) => [template, ...prev])
  }

  async function handleOnDelete(id: string) {
    const template = proposalTemplates.find((template) => template.id === id)

    if (template) {
      const confirmed = await Swal.fire({
        title: 'Deseja realmente apagar este modelo ?',
        icon: 'error',
        html:
          `Você está preste a deletar o modelo <strong style="color: #E53E3E;">${template.name}</strong>,` +
          ' confirme abaixo para deletar.',
        confirmButtonText: 'Deletar agora',
        cancelButtonText: 'Cancelar',
        focusConfirm: true,
        confirmButtonColor: '#E53E3E',
        iconColor: '#E53E3E',
        showCancelButton: true,
        cancelButtonColor: '#3F3F3F',
        cancelButtonAriaLabel: 'Cancelar',
        showCloseButton: true,
      })

      if (confirmed.isConfirmed) {
        try {
          await api.delete(`/proposals/template/${template.id}`)

          setProposalTemplates((prev) =>
            prev.filter((current) => current.id !== template.id),
          )
          setProposalTemplatesFilter((prev) =>
            prev.filter((current) => current.id !== template.id),
          )
        } catch (error) {
          alert('Opss, houve um erro.')
        }
      }
    }
  }

  return (
    <>
      <UploadTemplate
        isOpen={isOpenCreateNewContact}
        onClose={() => setIsOpenCreateNewContact(false)}
        onSaveTemplate={onSaveNewTemplate}
      />
      <Stack
        direction={'row'}
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Stack
          direction={'row'}
          gap={2}
          alignItems="center"
          justifyContent={'center'}
        >
          <Text fontWeight="extrabold">MODELOS</Text>
          <Badge variant={'outline'}>{proposalTemplatesFilter.length}</Badge>
        </Stack>
        <Button
          colorScheme={'blue'}
          leftIcon={<FiPlus />}
          onClick={() => setIsOpenCreateNewContact(true)}
        >
          Novo modelo
        </Button>
      </Stack>

      <Stack
        direction={'row'}
        alignItems="center"
        justifyContent={'space-between'}
        bg="gray.50"
        borderWidth="1px"
        borderStyle={'solid'}
        borderColor="gray.200"
        borderRadius={'5px'}
        padding={3}
        mb={4}
      >
        <Stack direction={'row'} alignItems="center">
          <InputGroup>
            <InputLeftElement pointerEvents={'none'}>
              <FiSearch />
            </InputLeftElement>
            <Input
              placeholder="Buscar pelo nome"
              onChange={handleFilterWithName}
            />
          </InputGroup>
        </Stack>
        <Stack
          direction={'row'}
          width="300px"
          alignItems="center"
          justifyContent={'flex-end'}
        >
          <Text fontSize={'12px'} color="gray.400">
            Resultado por página
          </Text>
          <Select width={'100px'}>
            <option value="5">5</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Select>
        </Stack>
      </Stack>
      {/* end Header filters */}
      {proposalTemplatesFilter.length > 0 ? (
        <Table
          variant="simple"
          borderWidth="1px"
          borderStyle={'solid'}
          borderColor="gray.200"
        >
          <Thead
            bg="gray.50"
            borderWidth="1px"
            borderStyle={'solid'}
            borderColor="gray.200"
          >
            <Tr>
              <Th>Nome</Th>
              <Th>Tamanho</Th>
              <Th>Data de upload</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {proposalTemplates.map((template) => (
              <Tr
                key={template.id}
                bg={'white'}
                borderWidth="1px"
                borderStyle={'solid'}
                borderColor="gray.200"
              >
                <Td
                  display={'flex'}
                  alignItems="center"
                  gap={4}
                  borderBottom={0}
                  height="100%"
                >
                  <span>
                    <a
                      href={template.template_url}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <Text fontWeight="medium">{template.name}</Text>
                    </a>
                    <Text fontSize={'14px'} color="gray.500">
                      {template.filename}
                    </Text>
                  </span>
                </Td>
                <Td>
                  <Text fontWeight="medium">{template.size}</Text>
                </Td>
                <Td>{template.created_at}</Td>
                <Td>
                  <Button
                    _hover={{
                      background: 'red.500',
                      color: 'white',
                    }}
                    onClick={() => handleOnDelete(template.id)}
                  >
                    <FiTrash size={16} />
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <p>Nenhum usuário encontrado</p>
      )}
    </>
  )
}
