import { useEffect, useState } from 'react'
import {
  Button,
  Heading,
  HStack,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Notification } from '../../components/Notification'
import { api } from '../../utils/api'
import { format, isToday, parseISO } from 'date-fns'
import { FiCalendar, FiEyeOff, FiInfo, FiUser } from 'react-icons/fi'

export interface TaksProps {
  id: string
  date: string
  done: boolean
  owner_id: string
  history_id: string
  tenant_id: string
  created_at: string
  updated_at: string
  historics: {
    id: string
    title: string
    content: string
    leads: {
      name: string
    }
  }
  dateFormat?: string
}

export function ListNotifications() {
  const [tasks, setTasks] = useState<TaksProps[]>([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [taskSelected, setTaskSelected] = useState<TaksProps>()

  function handleOpenNotification(taskId: string) {
    const task = tasks.find((t) => t.id === taskId)
    if (task) {
      setTaskSelected(task)
      onOpen()
    }
  }

  async function handleOnMark(taskId: string) {
    const { data } = await api.put(`/tasks/${taskId}`, {
      done: true,
    })

    if (data) {
      setTasks((prev) =>
        prev.map((task) => {
          if (task.id === taskId) return { ...task, ...data }
          return task
        }),
      )
    }
    console.log(data)
  }

  async function handleOnMarkOff(taskId: string) {
    const { data } = await api.put(`/tasks/${taskId}`, {
      done: false,
    })

    if (data) {
      setTasks((prev) =>
        prev.map((task) => {
          if (task.id === taskId) return { ...task, ...data }
          return task
        }),
      )
    }
    console.log(data)
  }
  useEffect(() => {
    ;(async () => {
      const { data } = await api.get<TaksProps[]>('/tasks')
      const dataFormatDateString = data.map((task) => ({
        ...task,
        dateFormat: format(parseISO(task.date), "dd/MM/yyyy 'às' hh:mm"),
      }))
      setTasks(dataFormatDateString)
    })()
  }, [])

  const tasksToday =
    tasks.length > 0
      ? tasks.filter((task) => {
          const date = parseISO(task.date)
          return isToday(date)
        })
      : []

  const previousTasks =
    tasks.length > 0
      ? tasks.filter((task) => {
          const date = parseISO(task.date)
          return !isToday(date)
        })
      : []

  return (
    <>
      <div>
        <Heading as="h1" size="md">
          Notifications
        </Heading>
        <Text>Veja todas suas notificações</Text>

        <Stack mt={8}>
          <Heading size="sm" mb={4}>
            Hoje
          </Heading>
          {tasksToday.map((task) => (
            <Notification
              key={task.id}
              data={{
                title: task.historics.title,
                date: `Hoje às ${format(parseISO(task.date), 'hh:mm')}`,
                description: task.historics.content,
                createdAt: task.created_at,
                done: task.done,
              }}
              onClickTitle={() => handleOpenNotification(task.id)}
              onMark={() => handleOnMark(task.id)}
              onMarkOff={() => handleOnMarkOff(task.id)}
            />
          ))}
        </Stack>
        <Stack mt={8}>
          <Heading size="sm" mb={4}>
            Anteriores
          </Heading>
          {previousTasks.map((task) => (
            <Notification
              key={task.id}
              data={{
                title: task.historics.title,
                date: task.dateFormat ? task.dateFormat : '',
                description: task.historics.content,
                createdAt: task.created_at,
                done: task.done,
              }}
              onClickTitle={() => handleOpenNotification(task.id)}
              onMark={() => handleOnMark(task.id)}
              onMarkOff={() => handleOnMarkOff(task.id)}
            />
          ))}
        </Stack>
      </div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{taskSelected?.historics.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <List mb={2}>
              <ListItem>
                <HStack alignItems="center" gap={8}>
                  <HStack>
                    <ListIcon as={FiCalendar} color="gray.400" />
                    <Text color="gray.400">Data</Text>
                  </HStack>
                  <Text>{taskSelected?.dateFormat}</Text>
                </HStack>
              </ListItem>
              <ListItem>
                <HStack alignItems="center" gap={8}>
                  <HStack>
                    <ListIcon as={FiUser} color="gray.400" />
                    <Text color="gray.400">Lead</Text>
                  </HStack>
                  <Text>{taskSelected?.historics.leads.name}</Text>
                </HStack>
              </ListItem>
              <ListItem>
                <HStack alignItems="center" gap={8}>
                  <HStack>
                    <ListIcon as={FiInfo} color="gray.400" />
                    <Text color="gray.400">Descrição</Text>
                  </HStack>
                </HStack>
              </ListItem>
            </List>
            <Text mb={4}>{taskSelected?.historics.content}</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Fechar
            </Button>
            {taskSelected?.done ? (
              <Button colorScheme="red" leftIcon={<FiEyeOff />}>
                Marcar como não lido
              </Button>
            ) : (
              <Button colorScheme="blue">Marcar como lido</Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
