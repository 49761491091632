import { useEffect, useState } from 'react'
import { useAuth } from '../../hooks/Auth'
import {
  Avatar,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Stack,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Badge,
  Select,
} from '@chakra-ui/react'
import { FiSearch, FiPlus } from 'react-icons/fi'

import { CreateNewProduct } from './CreateNewProduct'
import { Link } from 'react-router-dom'
import data from './data.json'
import { api } from '../../utils/api'
import { parseISO, format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

export interface IProducts {
  id: string
  description: string
  amount: number
  unit_of_measure: string
  suggested_amount?: number
  tenant_id: string
  created_at: string
  updated_at: string
}

export function Products() {
  const { user } = useAuth()
  const [products, setProducts] = useState<IProducts[]>([])
  const [isOpenCreateNewContact, setIsOpenCreateNewContact] = useState(false)

  function handleNewProduct(product: IProducts) {
    if (product) {
      const productFormat = {
        ...product,
        created_at: format(parseISO(product.created_at), 'dd MMMM, yyyy', {
          locale: ptBR,
        }),
      }
      setProducts([...products, productFormat])
    }
  }

  useEffect(() => {
    ;(async () => {
      const { data } = await api.get<IProducts[]>(`/products`)

      const dataFormat = data.map((product) => ({
        ...product,
        created_at: format(parseISO(product.created_at), 'dd MMMM, yyyy', {
          locale: ptBR,
        }),
      }))
      setProducts(dataFormat)
    })()
  }, [user])

  return (
    <>
      <CreateNewProduct
        isOpen={isOpenCreateNewContact}
        onClose={() => setIsOpenCreateNewContact(false)}
        onCreateProduct={handleNewProduct}
      />
      <Stack
        direction={'row'}
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Stack
          direction={'row'}
          gap={2}
          alignItems="center"
          justifyContent={'center'}
        >
          <Text fontWeight="extrabold">PRODUTOS</Text>
          <Badge variant={'outline'}>{data.length}</Badge>
        </Stack>
        <Button
          colorScheme={'blue'}
          leftIcon={<FiPlus />}
          onClick={() => setIsOpenCreateNewContact(true)}
        >
          Novo produto
        </Button>
      </Stack>
      {/* Header filters */}
      <Stack direction={'row'} alignItems="center" mb={4}></Stack>
      <Stack
        direction={'row'}
        alignItems="center"
        justifyContent={'space-between'}
        bg="gray.50"
        borderWidth="1px"
        borderStyle={'solid'}
        borderColor="gray.200"
        borderRadius={'5px'}
        padding={3}
        mb={4}
      >
        <Stack direction={'row'} alignItems="center">
          <InputGroup>
            <InputLeftElement pointerEvents={'none'}>
              <FiSearch />
            </InputLeftElement>
            <Input placeholder="Buscar pelo nome" />
          </InputGroup>
          <Select placeholder="Status">
            <option value="new">Novo</option>
            <option value="in-progress">Em progresso</option>
            <option value="open-business">Negócio Aberto</option>
            <option value="unqualified">Não qualificado</option>
            <option value="connected">Conectado</option>
            <option value="bad-time">Momento ruim</option>
          </Select>
        </Stack>
        <Stack
          direction={'row'}
          width="300px"
          alignItems="center"
          justifyContent={'flex-end'}
        >
          <Text fontSize={'12px'} color="gray.400">
            Resultado por página
          </Text>
          <Select width={'100px'}>
            <option value="5">5</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Select>
        </Stack>
      </Stack>
      {/* end Header filters */}
      {data.length > 0 ? (
        <Table
          variant="simple"
          borderWidth="1px"
          borderStyle={'solid'}
          borderColor="gray.200"
        >
          <Thead
            bg="gray.50"
            borderWidth="1px"
            borderStyle={'solid'}
            borderColor="gray.200"
          >
            <Tr>
              <Th>
                <Checkbox />
              </Th>
              <Th>Nome</Th>
              <Th>Unidade de medida</Th>
              <Th>Valor</Th>
              <Th>Data de criação</Th>
            </Tr>
          </Thead>
          <Tbody>
            {products.map((product) => (
              <Tr
                key={product.id}
                bg={'white'}
                borderWidth="1px"
                borderStyle={'solid'}
                borderColor="gray.200"
              >
                <Td px="6" width="8">
                  <Checkbox />
                </Td>
                <Td display={'flex'} alignItems="center" gap={4}>
                  <Avatar size={'md'} name={product.description} />
                  <span>
                    <Link to={`/dashboard/products`}>
                      <Text fontWeight="medium">{product.description}</Text>
                    </Link>
                    <Text fontSize={'14px'} color="gray.500"></Text>
                  </span>
                </Td>
                <Td>{product.unit_of_measure}</Td>
                <Td>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(product.amount)}
                </Td>
                <Td>{product.created_at}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <p>Nenhum produto encontrado</p>
      )}
    </>
  )
}
