import { useState } from 'react'
import { Avatar, Button, Text, VStack } from '@chakra-ui/react'
import { NavLink, Outlet } from 'react-router-dom'
import { useCredits } from '../../hooks/useCredits'
import * as Popover from '@radix-ui/react-popover'
import {
  FiHome,
  FiUsers,
  FiFileText,
  FiTag,
  FiMenu,
  FiLogOut,
  FiUserPlus,
  FiSettings,
} from 'react-icons/fi'
import { GiTwoCoins } from 'react-icons/gi'
import { GoBell } from 'react-icons/go'
import logo from '../../assets/logo-white.svg'
import styles from './styles.module.scss'
import { useAuth } from '../../hooks/Auth'

import { Credits } from './components/Credits'
import { Tasks } from './components/Tasks'

export function DashboardLayout() {
  const { signOut, user } = useAuth()
  const { credits } = useCredits()
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const userIsMasterOrAdmin = ['master', 'admin'].includes(user.role)

  return (
    <div
      className={
        menuIsOpen ? styles.wrapper : `${styles.wrapper} ${styles.closed}`
      }
    >
      <div className={styles.sidebar}>
        <div className={styles.navbrand}>
          <img src={logo} alt="SeekSales" />
        </div>
        <nav className={styles.navbar}>
          <div>
            <ul>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? styles.isActive : ''
                  }
                  to={'/dashboard'}
                  end
                >
                  <FiHome size={16} />
                  <span>Dashboard</span>
                </NavLink>
              </li>
            </ul>

            <ul>
              <li className={styles.title}>COMERCIAL</li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? styles.isActive : ''
                  }
                  to={'/dashboard/leads'}
                  end
                >
                  <FiUsers size={16} />
                  <span>Leads</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? styles.isActive : ''
                  }
                  to={'/dashboard/products'}
                  end
                >
                  <FiTag size={16} />
                  <span>Produtos</span>
                </NavLink>
              </li>
            </ul>

            <ul>
              <li className={styles.title}>APOIO</li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? styles.isActive : ''
                  }
                  to={'/dashboard/proposal-template'}
                  end
                >
                  <FiFileText size={16} />
                  <span>Modelos de proposta</span>
                </NavLink>
              </li>
              {userIsMasterOrAdmin && (
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? styles.isActive : ''
                    }
                    to={'/dashboard/users'}
                    end
                  >
                    <FiUserPlus size={16} />
                    <span>Usuários</span>
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? styles.isActive : ''
                  }
                  to={'/dashboard/settings'}
                  end
                >
                  <FiSettings size={16} />
                  <span>Configurações</span>
                </NavLink>
              </li>
            </ul>
          </div>
          <ul>
            <li className={styles.title}>CONTA</li>
            <li className={styles.profile}>
              <NavLink
                className={({ isActive }) => (isActive ? styles.isActive : '')}
                to="/dashboard/settings"
                end
              >
                <Avatar
                  name={`${user.first_name} ${user.last_name}`}
                  src={`${process.env.REACT_APP_AWS_BUCKET_URL}/avatar/${user?.avatar}`}
                  size={menuIsOpen ? 'md' : 'sm'}
                />
                <span>
                  <VStack alignItems="flex-start">
                    <Text
                      fontSize="sm"
                      color="gray.200"
                    >{`${user.first_name} ${user.last_name}`}</Text>
                    <Text fontSize="xs" color="gray.400">
                      {user.tenant.companies.name}
                    </Text>
                  </VStack>
                </span>
              </NavLink>
            </li>
            <li className={styles.actionLogout}>
              <Button
                w={'100%'}
                leftIcon={<FiLogOut />}
                bg="#383838"
                color={'#C4C4C4'}
                _hover={{
                  bg: '#383838',
                  color: '#C4C4C4',
                  filter: 'brightness(.8)',
                }}
                onClick={signOut}
              >
                <span>Sair</span>
              </Button>
            </li>
          </ul>
        </nav>
      </div>
      <div className={styles.container}>
        <div className={styles.topMenu}>
          <button onClick={() => setMenuIsOpen(!menuIsOpen)}>
            <FiMenu size={16} />
          </button>
          <div className={styles.creditsContainer}>
            <Popover.Root>
              <Popover.Trigger
                className={styles.credits}
                title="Créditos para ligação"
              >
                <GiTwoCoins size={24} />
                <p>{credits ? credits.amount_currency : 'R$ 0,00'}</p>
              </Popover.Trigger>
              <Popover.Anchor />
              <Credits />
            </Popover.Root>

            <Popover.Root>
              <Popover.Trigger title="Tarefas do dia" asChild>
                <button>
                  <GoBell />
                </button>
              </Popover.Trigger>
              <Popover.Trigger />
              <Tasks />
            </Popover.Root>
          </div>
        </div>
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
