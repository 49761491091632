import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import { CheckoutForm } from '../../components/CheckoutForm'
import { Container, Content, Heading } from './styles'
import { useParams } from 'react-router-dom'

const stripe = loadStripe(
  'pk_test_51J5LFxJP1wWiMWyJbsucTj7bkFN1VIiWGRFx2xef9GVAnOyMay7Zv5E8eVCKRZGp95dzNGv1BPeDNGRXq6LWp7IH00nsBcsEYb',
)

export function CheckoutCredits() {
  const { clientSecret } = useParams()

  return (
    <Container>
      <Content>
        <Heading>
          <div>
            <h1>Créditos</h1>
            <p>
              Adicione o valor de créditos que você deseja, nós utilizamos a
              plataforma Stripe para efetuar nossos pagamentos que é altamente
              seguro, se possuir alguma duvida entre em contato com nosso time.
            </p>
          </div>
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png"
            alt="Stripe"
          />
        </Heading>
        <Elements
          options={{
            clientSecret,
            appearance: {
              theme: 'stripe',
            },
          }}
          stripe={stripe}
        >
          <CheckoutForm />
        </Elements>
      </Content>
    </Container>
  )
}
