import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react'
import Select from 'react-select'
import { api } from '../../../utils/api'
import { IUser } from '..'

interface Props {
  isOpen?: boolean
  onClose: () => void
  onRegisterUser: (user: IUser) => void
}

const optionsTypes = [
  { value: 'user', label: 'Usuário' },
  { value: 'admin', label: 'Administrador' },
]

export function CreateNewUser({
  onClose,
  isOpen = false,
  onRegisterUser,
}: Props) {
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  })

  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      role: '',
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true)

        const schema = Yup.object({
          first_name: Yup.string().required('Nome é obrigatório'),
          last_name: Yup.string().required('Sobrenome é obrigatório'),
          email: Yup.string().email(),
          password: Yup.string().required(),
          role: Yup.string(),
        })

        await schema.validate(values, {
          abortEarly: false,
        })

        const response = await api.post(`/users`, values)

        onRegisterUser(response.data)
        onClose()

        formik.resetForm()

        toast({
          title: 'Contato cadastrado com sucesso!',
          status: 'success',
        })
      } catch (error: any) {
        if (error instanceof Yup.ValidationError) {
          return toast({
            title: 'Dados obrigatórios não preenchidos',
            description: 'Verifique os dados incompletos.',
            status: 'error',
          })
        }

        toast({
          title: error.response.data.message,
          status: 'error',
        })
      } finally {
        setIsLoading(false)
      }
    },
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent>
        <ModalHeader background={'brand.100'} color="white">
          Criar novo usuário
        </ModalHeader>
        <ModalCloseButton color={'gray.200'} />

        <ModalBody pt={4}>
          <form onSubmit={formik.handleSubmit}>
            <Stack direction={'row'} gap={4}>
              <Input
                name="first_name"
                onChange={formik.handleChange}
                value={formik.values.first_name}
                placeholder="Nome"
                mb={4}
              />
              <Input
                name="last_name"
                onChange={formik.handleChange}
                value={formik.values.last_name}
                placeholder="Sobrenome"
                mb={4}
              />
            </Stack>

            <Input
              name="email"
              placeholder="E-mail"
              onChange={formik.handleChange}
              value={formik.values.email}
              mb={4}
            />
            <Stack
              display={'grid'}
              gridTemplateColumns="1fr 1fr"
              alignItems={'flex-end'}
              gap={4}
            >
              <Input
                name="password"
                placeholder="Senha"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              <div>
                <Select
                  options={optionsTypes}
                  name="owner_id"
                  placeholder="Função"
                  onChange={(value) =>
                    formik.setFieldValue('role', value?.value)
                  }
                />
              </div>
            </Stack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Fechar</Button>
          <Button
            onClick={formik.submitForm}
            ml={4}
            bgColor={'brand.100'}
            color={'white'}
            _hover={{
              bg: 'brand.100',
              color: 'white',
              filter: 'brightness(.8)',
            }}
            isLoading={isLoading}
          >
            Criar usuário
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
