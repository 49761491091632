import { useEffect, useState } from 'react'
import { FiCreditCard, FiFile, FiShoppingCart } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { Input } from '../../../components/Input'
import { NotResult } from '../../../components/NotResult'
import { api } from '../../../utils/api'
import { dateFormatter, priceFormatter } from '../../../utils/formatter'
import { PaymentsContainer, PaymentsTable, SearchFormContainer } from './styles'

interface Payments {
  id: string
  client_secret: string
  payment_intent: string
  amount: number
  tenant_id: string
  status: 'incompleted' | 'succeeded'
  type: 'credits' | 'signature'
  receipt_url?: string
  created_at: string
  updated_at: string
}

const TYPES_LABEL = {
  credits: 'Créditos para ligação',
  signature: 'Assinatura',
} as const

export function Billing() {
  const { colors } = useTheme()
  const [payments, setPayments] = useState<Payments[]>([])

  async function fetchPayments() {
    const response = await api.get('/payments')
    setPayments(response.data)
  }
  useEffect(() => {
    fetchPayments()
  }, [])

  return (
    <PaymentsContainer>
      <SearchFormContainer>
        <Input
          prompt=""
          placeholder="Buscar pelo tipo de pagamento"
          type="text"
        />
        <button>Buscar</button>
      </SearchFormContainer>
      {payments.length > 0 ? (
        <PaymentsTable>
          <thead>
            <tr>
              <th>Data</th>
              <th>Referente</th>
              <th>Valor</th>
              <th className="center">Recibo</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) => (
              <tr
                key={payment.id}
                className={
                  payment.status === 'incompleted' ? 'incompleted' : ''
                }
              >
                <td>{dateFormatter.format(new Date(payment.created_at))}</td>
                <td>{TYPES_LABEL[payment.type]}</td>
                <td>{priceFormatter.format(payment.amount / 100)}</td>
                <td align="center">
                  {payment.status === 'succeeded' ? (
                    <a
                      href={payment.receipt_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Visualizar recibo de pagamento"
                    >
                      <FiFile color={colors.brand[100]} />
                    </a>
                  ) : (
                    <Link
                      to={`/dashboard/checkout-credits/${payment.client_secret}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Finalizar pagamento"
                    >
                      <FiShoppingCart color={colors.gray[500]} />
                    </Link>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </PaymentsTable>
      ) : (
        <NotResult
          icon={FiCreditCard}
          title="Ainda não possui nenhum pagamento realizado"
        />
      )}
    </PaymentsContainer>
  )
}
