import { ChangeEvent } from 'react'
import { Input, InputProps } from '@chakra-ui/react'

interface Props extends InputProps {
  onChangeValue: (value: number) => void
}

export function InputReal({ onChangeValue, ...rest }: Props) {
  function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
    let value = event.target.value
    value = value.replace(/\D/g, '')

    onChangeValue(Number(value) / 100)
    // Value Display
    value = value.replace(/(\d)(\d{2})$/, '$1,$2')
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.')
    event.currentTarget.value = value
    return event
  }

  return <Input onChange={handleOnChange} {...rest} />
}
