import styled, { keyframes } from 'styled-components'
import * as Popover from '@radix-ui/react-popover'

const scaleIn = keyframes`
    0% { 
        opacity: 0; 
        transform: scale(0);
    }

    100% { 
        opacity: 1; 
        transform: scale(1);
    }
`

export const PopoverArrow = styled(Popover.Arrow)`
  fill: white;
`

export const PopoverContent = styled(Popover.Content)`
  transform-origin: var(--radix-popover-content-transform-origin);
  transform: translate(-24px);
  animation: ${scaleIn} 0.3s ease-out;
  background: white;
  border-radius: 6px;
  box-shadow: 0 4px 20px 0 rgb(25 25 34/24%);
  z-index: 99;
  margin-top: 1rem;
  min-width: 448px;

  header {
    padding: 0 1.5rem;

    strong {
      font-size: 0.75rem;
      color: ${(props) => props.theme.colors.brand[900]};
      display: block;
      margin-bottom: 1rem;
    }
  }
`
export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;

  a {
    align-self: center;
    margin: 1rem 0;
    font-size: 0.625rem;
    color: ${(props) => props.theme.colors.brand[100]};

    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const Notification = styled.div`
  display: flex;
  padding: 1.5rem;
  gap: 0.625rem;
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray[50]};

  &:hover {
    background-color: ${(props) => props.theme.colors.gray[50]};
    transition: background-color, 0.2s;
  }

  div {
    display: flex;
    flex-direction: column;

    > strong {
      font-size: 0.875rem;
      color: ${(props) => props.theme.colors.brand[900]};
    }

    span {
      font-size: 0.625rem;
      color: ${(props) => props.theme.colors.gray[300]};
    }
  }
`
