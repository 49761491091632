import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Input,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react'

import Select from 'react-select'
import { api } from '../../../utils/api'
import { IProducts } from '..'
import { GroupedOption, groupedOptions } from './dataUnitOfMeasures'

interface Props {
  isOpen?: boolean
  onClose: () => void
  onCreateProduct: (product: IProducts) => void
}

interface ReactSelectData {
  value: string
  label: string
}

export function CreateNewProduct({
  onClose,
  isOpen = false,
  onCreateProduct,
}: Props) {
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  })

  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      description: '',
      amount: 0,
      unit_of_measure: '',
      suggested_amount: 0,
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true)

        const schema = Yup.object({
          description: Yup.string().required(
            'Descrição do produto é obrigatório',
          ),
          amount: Yup.number().required('Valor do produto é obrigatório'),
          unit_of_measure: Yup.string(),
          suggested_amount: Yup.number().transform(Number),
        })

        await schema.validate(values, {
          abortEarly: true,
        })

        const { data } = await api.post(`/products`, values)

        onCreateProduct(data)
        formik.resetForm()
        onClose()

        toast({
          title: 'Produto cadastrado com sucesso!',
          status: 'success',
        })
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          return toast({
            title: 'Dados obrigatórios não preenchidos',
            description: 'Verifique os dados incompletos.',
            status: 'error',
          })
        }

        toast({
          title: 'Ops, houve um error inesperado',
          status: 'error',
        })
      } finally {
        setIsLoading(false)
      }
    },
  })

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    let value = event.target.value
    value = value.replace(/\D/g, '')

    formik.setFieldValue('amount', Number(value) / 100)
    console.log(Number(value) / 100)
    // Value Display
    value = value.replace(/(\d)(\d{2})$/, '$1,$2')
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.')
    event.currentTarget.value = value
    return event
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent>
        <ModalHeader background={'brand.100'} color="white">
          Criar novo produto
        </ModalHeader>
        <ModalCloseButton color={'gray.200'} />

        <ModalBody pt={4}>
          <form onSubmit={formik.handleSubmit}>
            <Stack mb={4}>
              <Text fontSize={'12px'} color={'brand.500'}>
                Descrição{' '}
                <Text as={'span'} color={'red.500'}>
                  *
                </Text>
              </Text>
              <Input
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
                placeholder="Nome"
              />
            </Stack>

            <Stack mb={4}>
              <Text fontSize={'12px'} color={'brand.500'}>
                Valor{' '}
                <Text as={'span'} color={'red.500'}>
                  *
                </Text>
              </Text>
              <InputGroup>
                <InputLeftAddon>R$</InputLeftAddon>
                <Input
                  name="amount"
                  placeholder="Valor do produto"
                  required
                  onChange={handleOnChange}
                />
              </InputGroup>
            </Stack>

            <Stack mb={4}>
              <Text fontSize={'12px'} color={'brand.500'}>
                Unidade de medida{' '}
                <Text as={'span'} color={'red.500'}>
                  *
                </Text>
              </Text>
              <Select<ReactSelectData, false, GroupedOption>
                options={groupedOptions}
                name="unit_of_measure"
                placeholder="Selecione a unidade"
                onChange={(value) =>
                  formik.setFieldValue('unit_of_measure', value?.value)
                }
              />
            </Stack>

            <Stack mb={4}>
              <Text fontSize={'12px'} color={'brand.500'}>
                Quantidade sugerida
              </Text>
              <InputGroup>
                <Input
                  type="number"
                  name="suggested_amount"
                  placeholder="Quantidade sugerida"
                  onChange={formik.handleChange}
                  value={formik.values.suggested_amount}
                  required
                />
              </InputGroup>
            </Stack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Fechar</Button>
          <Button
            onClick={formik.submitForm}
            ml={4}
            bgColor={'brand.100'}
            color={'white'}
            _hover={{
              bg: 'brand.100',
              color: 'white',
              filter: 'brightness(.8)',
            }}
            isLoading={isLoading}
          >
            Criar produto
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
