import { useState, useEffect } from 'react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Stack,
  Text,
  Input,
  RadioGroup,
  Radio,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import Select, { SingleValue } from 'react-select'
import { useFormik } from 'formik'
import { ILeads } from '../../../Dashboard'
import { api } from '../../../../utils/api'
import { useAuth } from '../../../../hooks/Auth'
import { places } from '../../../../utils/places'

interface EditLeadProps {
  isOpen: boolean
  onClose: () => void
  lead: ILeads | undefined
  onUpdateLead: (lead: ILeads) => void
}

interface ReactSelectData {
  value: string
  label: string
}

const optionsStatus = [
  { value: 'new', label: 'Novo' },
  { value: 'in-progress', label: 'Em andamento' },
  { value: 'open-business', label: 'Negocio aberto' },
  { value: 'unqualified', label: 'Não qualificado' },
  { value: 'bad-time', label: 'Momento ruim' },
]

const optionsOrigins = [
  { value: 'website', label: 'Website' },
  { value: 'recommendation', label: 'Indicação' },
]

export function EditLead({
  isOpen,
  onClose,
  lead,
  onUpdateLead,
}: EditLeadProps) {
  const { user } = useAuth()
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  })
  const [typeContact, setTypeContact] = useState<string>('')
  const [owners, setOwners] = useState<ReactSelectData[]>()
  const [states, setStates] = useState<ReactSelectData[]>()
  const [counties, setCounties] = useState<ReactSelectData[]>()
  const [isLoadingCounties, setIsLoadingCounties] = useState(false)

  const [ownerSelected, setOwnerSelected] = useState<
    ReactSelectData | undefined
  >(undefined)
  const [statusSelected, setStatusSelected] = useState<
    ReactSelectData | undefined
  >(undefined)
  const [originSelected, setOriginSelected] = useState<
    ReactSelectData | undefined
  >(undefined)

  const [stateSelected, setStateSelected] = useState<
    ReactSelectData | undefined
  >(undefined)
  const [countySelected, setCountySelected] = useState<
    ReactSelectData | undefined
  >(undefined)

  const [isLoading, setIsLoading] = useState(false)

  const initialValues = {
    ...lead,
    owner_selected: undefined,
    status_selected: undefined,
    origin_selected: undefined,
    address: {
      state: '',
      county: '',
      zip_code: '',
    },
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    async onSubmit(values) {
      try {
        setIsLoading(true)
        const { data } = await api.put(`/leads/${values.id}`, values)

        onUpdateLead(data)
        onClose()
        toast({
          title: 'Lead atualizado com sucesso',
          status: 'success',
        })
      } catch (error: any) {
        if (error.response) {
          toast({
            title: error.response.data.message,
            status: 'error',
          })
        }
      } finally {
        setIsLoading(false)
      }
    },
  })

  useEffect(() => {
    if (lead && lead.type) setTypeContact(lead.type)
  }, [lead])

  useEffect(() => {
    if (lead && lead.owner_id && owners) {
      const owner = owners.find((value) => value.value === lead.owner_id)

      if (owner) setOwnerSelected(owner)
    }
    if (lead && lead.status) {
      const status = optionsStatus.find((value) => value.value === lead.status)

      if (status) setStatusSelected(status)
    }
    if (lead && lead.origin_id) {
      const status = optionsOrigins.find((value) => value.value === lead.status)

      if (status) setOriginSelected(status)
    }
  }, [lead, owners])
  /** Checking (lead.address and states) for update form */
  useEffect(() => {
    ;(async () => {
      if (lead && lead.address) {
        if (states) {
          setIsLoadingCounties(true)
          const stateLead = states.find(
            (state) => state.label === lead.address?.state,
          )

          if (stateLead) {
            const { data } = await places.get(
              `/localidades/estados/${stateLead.value}/distritos`,
            )

            const contyFormat = data.map((county: any) => ({
              label: county.nome,
              value: county.id,
            })) as ReactSelectData[]

            const countyLead = contyFormat.find(
              (county) => county.label === lead.address?.county,
            )

            setStateSelected(stateLead)
            setCountySelected(countyLead)

            setCounties(contyFormat)
            setIsLoadingCounties(false)
          }
        }
      }
    })()
  }, [lead, states])

  useEffect(() => {
    async function getUsers() {
      const { data } = await api.get(`/users`)

      const usersFormat = data.map((user: any) => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
      }))

      setOwners(usersFormat)
    }
    getUsers()
    async function getStates() {
      const { data } = await places.get('/localidades/estados')

      if (data) {
        const statesFormat = data.map((st: any) => ({
          label: st.nome,
          value: st.id,
        }))
        setStates(statesFormat)
      }
    }
    getStates()
  }, [user])

  async function handleSelectedState(state: SingleValue<ReactSelectData>) {
    if (state) {
      try {
        setStateSelected(state)
        formik.setFieldValue('address.state', state.label)
        // reset county
        formik.setFieldValue('address.county', '')
        setCountySelected(undefined)

        setIsLoadingCounties(true)
        const { data } = await places.get(
          `/localidades/estados/${state.value}/distritos`,
        )

        const contyFormat = data.map((county: any) => ({
          label: county.nome,
          value: county.nome,
        }))

        setCounties(contyFormat)
      } catch (error) {
      } finally {
        setIsLoadingCounties(false)
      }
    }
  }

  function handleSelectedCounty(county: SingleValue<ReactSelectData>) {
    if (county) {
      setCountySelected(county)
      formik.setFieldValue('address.county', county.label)
    }
  }

  function handleSelectedOwner(owner: SingleValue<ReactSelectData>) {
    if (owner) {
      setOwnerSelected(owner as ReactSelectData)
      formik.setFieldValue('owner_id', owner.value)
    }
  }

  function handleSelectedStatus(status: SingleValue<ReactSelectData>) {
    if (status) {
      setStatusSelected(status as ReactSelectData)
      formik.setFieldValue('status', status)
    }
  }

  function handleSelectedOrigin(origin: SingleValue<ReactSelectData>) {
    if (origin) {
      setOriginSelected(origin as ReactSelectData)
      formik.setFieldValue('origin_id', origin)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent>
        <ModalHeader background={'brand.100'} color="white">
          Editar Lead
        </ModalHeader>
        <ModalCloseButton color={'gray.200'} />

        <ModalBody pt={4}>
          <form onSubmit={formik.handleSubmit}>
            <Tabs>
              <TabList>
                <Tab>Informação</Tab>
                <Tab>Detalhes</Tab>
                <Tab>Endereço</Tab>
              </TabList>
              <TabPanels>
                <TabPanel px={0}>
                  <Stack mb={4}>
                    <Text fontSize={'12px'} color={'brand.500'}>
                      Nome{' '}
                      <Text as={'span'} color={'red.500'}>
                        *
                      </Text>
                    </Text>
                    <Input
                      name="name"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      placeholder="Nome"
                    />
                  </Stack>
                  <Stack mb={4}>
                    <Text fontSize={'12px'} color={'brand.500'}>
                      Website
                    </Text>
                    <Input
                      name="website"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.website || ''}
                      placeholder="www.exemplo.com.br"
                    />
                  </Stack>
                  <Stack mb={4}>
                    <Text fontSize={'12px'} color={'brand.500'}>
                      Descrição
                    </Text>
                    <Textarea
                      name="description"
                      onChange={formik.handleChange}
                      value={formik.values.description}
                      resize="none"
                    />
                  </Stack>
                </TabPanel>
                <TabPanel px={0}>
                  <Stack mb={4}>
                    <Text fontSize={'12px'} color={'brand.500'}>
                      Responsável pelo lead
                    </Text>
                    <Select
                      options={owners}
                      name="owner_id"
                      placeholder="Responsável pelo lead"
                      value={ownerSelected}
                      onChange={handleSelectedOwner}
                    />
                  </Stack>
                  <Stack
                    display={'grid'}
                    gridTemplateColumns="1fr 1fr"
                    alignItems={'flex-end'}
                    gap={4}
                    mt={4}
                  >
                    <Stack>
                      <Text fontSize={'12px'} color={'brand.500'}>
                        Status
                      </Text>
                      <Select
                        options={optionsStatus}
                        name="status"
                        placeholder="Status"
                        value={statusSelected}
                        onChange={handleSelectedStatus}
                      />
                    </Stack>
                    <Stack>
                      <Text fontSize={'12px'} color={'brand.500'}>
                        Origem
                      </Text>
                      <Select
                        options={optionsOrigins}
                        name="origin_id"
                        placeholder="Origem"
                        value={originSelected}
                        onChange={handleSelectedOrigin}
                      />
                    </Stack>
                  </Stack>
                  <Stack
                    display={'grid'}
                    gridTemplateColumns="1fr 1fr"
                    alignItems={'flex-end'}
                    gap={4}
                    mt={4}
                  >
                    <Stack>
                      <Text fontSize={'12px'} color={'brand.500'}>
                        Setor
                      </Text>
                      <Input
                        name="sector"
                        onChange={formik.handleChange}
                        value={formik.values.sector || ''}
                        placeholder="Construtora"
                      />
                    </Stack>
                    <Stack>
                      <Text fontSize={'12px'} color={'brand.500'}>
                        Número de funcionários
                      </Text>
                      <Input
                        name="number_employees"
                        onChange={formik.handleChange}
                        value={formik.values.number_employees || ''}
                        placeholder="5"
                      />
                    </Stack>
                  </Stack>
                  <Stack
                    display={'grid'}
                    gridTemplateColumns="1fr 1fr"
                    alignItems={'flex-end'}
                    gap={4}
                    mt={4}
                  >
                    <Stack>
                      <Text fontSize={'14px'} color={'gray.400'}>
                        Tipo do contato:
                      </Text>
                      <RadioGroup
                        value={typeContact}
                        onChange={(value) => {
                          setTypeContact(value)
                          formik.setFieldValue('type', value)
                        }}
                      >
                        <Stack spacing={5} direction="row">
                          <Radio value={'project'}>Projeto/Obra</Radio>
                          <Radio value={'company'}>Empresa</Radio>
                        </Stack>
                      </RadioGroup>
                    </Stack>
                  </Stack>
                  {/* <Stack mb={4}>
                                    <Text fontSize={"12px"} color={"brand.500"}>Código postal <Text as={"span"} color={"red.500"}>*</Text></Text>
                                    <Input name='name' type="text" onChange={formik.handleChange} value={formik.values.name}  placeholder='Nome'/>
                                </Stack> */}
                </TabPanel>
                <TabPanel px={0}>
                  <Stack mb={4}>
                    <Text fontSize={'12px'} color={'brand.500'}>
                      Estado
                    </Text>
                    <Select
                      options={states}
                      name="state"
                      placeholder="Selecione o estado"
                      value={stateSelected}
                      onChange={handleSelectedState}
                    />
                  </Stack>
                  <Stack mb={4}>
                    <Text fontSize={'12px'} color={'brand.500'}>
                      Municipio
                    </Text>
                    <Select
                      options={counties}
                      name="county"
                      placeholder="Selecione o município"
                      value={countySelected}
                      onChange={handleSelectedCounty}
                      isLoading={isLoadingCounties}
                    />
                  </Stack>
                  <Stack mb={4}>
                    <Text fontSize={'12px'} color={'brand.500'}>
                      CEP{' '}
                      <Text as={'span'} color={'red.500'}>
                        *
                      </Text>
                    </Text>
                    <Input
                      name="address.zip_code"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.address.zip_code}
                      placeholder="CEP"
                    />
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Fechar</Button>
          <Button
            onClick={formik.submitForm}
            ml={4}
            bgColor={'brand.100'}
            color={'white'}
            _hover={{
              bg: 'brand.100',
              color: 'white',
              filter: 'brightness(.8)',
            }}
            isLoading={isLoading}
            disabled={
              JSON.stringify(initialValues) === JSON.stringify(formik.values)
            }
          >
            Atualizar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
