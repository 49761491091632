import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  label {
    font-size: 0.75rem;
    color: ${(props) => props.theme.colors.gray[500]};

    span {
      color: ${(props) => props.theme.colors.red[500]};
    }
  }

  input {
    flex: 1;
    border-radius: 6px;
    border: 0;
    max-height: 2.5rem;
    padding: 1rem;
    background: inherit;
    border-radius: 0.375rem;
    border: 1px solid ${(props) => props.theme.colors.gray[200]};
    outline: none;

    &:focus {
      box-shadow: 0 0 0 2px ${(props) => props.theme.colors.brand[100]};
    }
  }
`
