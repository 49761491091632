import { ComponentType } from 'react'
import { Stack, Text, useTheme } from '@chakra-ui/react'
import { IconBaseProps } from 'react-icons'

type NotResultProps = {
  title: string
  icon: ComponentType<IconBaseProps>
}

export function NotResult({ title, icon: Icon }: NotResultProps) {
  const theme = useTheme()

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      bg="gray.200"
      rounded="md"
      flex={1}
      py={6}
      px={4}
    >
      <Icon size={24} color={theme.colors.gray[500]} />
      <Text color="gray.500">{title}</Text>
    </Stack>
  )
}
