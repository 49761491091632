import styled from 'styled-components'

export const PaymentsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > div {
    width: 100%;
  }
`
export const PaymentsTable = styled.table`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.gray[200]};
  background: white;

  thead {
    background-color: ${(props) => props.theme.colors.gray[50]};

    th {
      padding: 0.75rem 1.5rem;
      background-color: ${(props) => props.theme.colors.gray[50]};
      text-align: start;
      text-transform: uppercase;
      font-size: 0.75rem;

      &.center {
        text-align: center;
      }
    }
  }

  tr.incompleted {
    color: #cecece;
  }

  td {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray[50]};

    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    a:link:hover {
      filter: brightness(0.8);
    }
  }
`

export const SearchFormContainer = styled.form`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray[50]};
  border: 1px solid ${(props) => props.theme.colors.gray[200]};
  padding: 0.75rem;
  margin-bottom: 1.5rem;

  button {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 1rem;

    background-color: ${(props) => props.theme.colors.brand[100]};
    color: white;
    font-weight: bold;
    border-radius: 0.375rem;
    cursor: pointer;

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    &:hover {
      filter: brightness(0.8);
      transition: filter 0.2s;
    }
  }
`
