import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
`

export const Content = styled.div`
  flex: 1;
  max-width: 720px;
  margin: 0 auto;
  padding: 4rem 0;

  h1 {
    font-size: 2rem;
    font-weight: bold;
  }
`

export const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  gap: 2rem;

  img {
    height: 4rem;
  }
`

export const FormContainer = styled.form`
  margin-top: 2rem;

  button {
    margin-top: 1rem;
  }
`
