import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Tag,
  Stack,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Badge,
  Select,
  Skeleton,
  SkeletonCircle,
  Box,
} from '@chakra-ui/react'
import {
  FiSearch,
  FiPlus,
  FiCornerDownRight,
  FiEdit2,
  FiTrash,
} from 'react-icons/fi'
import { useAuth } from '../../hooks/Auth'
import { api } from '../../utils/api'
import { CreateNewContact } from './CreateNewContact'
import { Link } from 'react-router-dom'
import { AssignResponsibleLeads } from './AssignResponsibleLeads'
import Swal from 'sweetalert2'
import { format, parseISO } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

export interface IContacts {
  id: string
  first_name: string
  last_name: string
  email: string
  phone: string
  office: string
  tenant_id: string
  lead_id: string
  created_at: string
  updated_at: string
}
export interface ILeads {
  id: string
  name: string
  status: 'new' | 'in-progress' | 'open-business' | 'unqualified' | 'bad-time'
  type?: 'project' | 'company'
  owner_id: string
  tenant_id: string
  created_at: string
  updated_at: string
  origin_id?: string
  origin?: {
    id: string
    name: string
    tenant_id: string
    created_at: string
    updated_at: string
  }
  owner?: {
    id: string
    first_name: string
    last_name: string
    avatar?: string
  }
  avatar?: string
  selected?: boolean
  contacts: IContacts[]
  sector?: string
  number_employees?: string
  website?: string
  phone?: string
  description?: string
  address_id?: string
  address?: {
    id: string
    state: string
    county: string
    zip_code: string
    description?: string
    street?: string
  }
}

const statusLabels = {
  new: 'Novo',
  'in-progress': 'Em andamento',
  'open-business': 'Negocio aberto',
  unqualified: 'Não qualificado',
  'bad-time': 'Momento ruim',
}

const typesLabels = {
  project: {
    color: 'blue',
    text: 'Projeto',
  },
  company: {
    color: 'orange',
    text: 'Empresa',
  },
}

export function Dashboard() {
  const { user } = useAuth()
  const [contacts, setContacts] = useState<ILeads[]>([])
  const [contactsFilter, setContactsFilter] = useState<ILeads[]>([])
  const [isLoadingLeads, setIsLoadingLeads] = useState(true)
  const [filterOwner, setFilterOwner] = useState<
    'all' | 'my-contacts' | 'not-assigned'
  >('all')
  const [isOpenCreateNewContact, setIsOpenCreateNewContact] = useState(false)
  const [isOpenAssinResponsibleLead, setIsOpenAssignResponsibleLead] =
    useState(false)
  const [isSelectedAll, setIsSelectedAll] = useState(false)
  const [numberOfSelectedLeads, setNumberOfSelectedLeads] = useState(0)

  function handleNewContact(contact: ILeads) {
    if (contact) {
      const createdAt = format(parseISO(contact.created_at), 'dd MMMM, yyyy', {
        locale: ptBR,
      })
      const newLead = {
        ...contact,
        created_at: createdAt,
      }
      setContacts([...contacts, newLead])
    }
  }

  function handleFilterWithName(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value) {
      const dataFilter = contactsFilter.filter((item) => {
        const itemLowerCase = item.name.toLowerCase()
        const valueLowerCase = event.target.value.toLowerCase()

        if (itemLowerCase.includes(valueLowerCase)) return item
        return null
      })
      return setContactsFilter(dataFilter)
    }
    setContactsFilter(contacts)
  }

  function handleFilterStatus(event: React.ChangeEvent<HTMLSelectElement>) {
    if (event.target.value) {
      if (event.target.value === 'all') return setContactsFilter(contacts)

      const dataFilter = contacts.filter(
        (contact) => contact.status === event.target.value,
      )

      setContactsFilter(dataFilter)
    }
  }

  function handleSelectLead(id: string) {
    const updateUsers = contactsFilter.map((lead) => {
      if (lead.id === id) {
        lead.selected = !lead.selected
      }
      return lead
    })

    const checkExistsLeadSelected = updateUsers.reduce(
      (accumulator: number, currentItem: ILeads) => {
        if (currentItem.selected) {
          accumulator += 1
        }
        return accumulator
      },
      0,
    )

    setNumberOfSelectedLeads(checkExistsLeadSelected)
    setContactsFilter(updateUsers)
  }

  function handleSelectedAllLead() {
    const updateUsers = contactsFilter.map((lead) => {
      lead.selected = !isSelectedAll
      return lead
    })

    const checkExistsLeadSelected = contactsFilter.reduce(
      (accumulator: number, currentItem: ILeads) => {
        if (currentItem.selected) {
          accumulator += 1
        }
        return accumulator
      },
      0,
    )

    setNumberOfSelectedLeads(checkExistsLeadSelected)
    setIsSelectedAll(!isSelectedAll)
    setContactsFilter(updateUsers)
  }

  /** Functions actions */
  async function handleDeleteLeads() {
    if (numberOfSelectedLeads > 0) {
      const leadsSelected = contactsFilter.filter((lead) => !!lead.selected)

      if (leadsSelected) {
        const confirmed = await Swal.fire({
          title: 'Deseja realmente apagar este usuário ?',
          icon: 'error',
          html:
            `Você está preste a deletar <strong style="color: #E53E3E;">${
              leadsSelected.length
            }</strong> ${leadsSelected.length > 1 ? 'usuários' : 'usuário'}, ` +
            ' confirme abaixo para deletar.',
          confirmButtonText: 'Deletar agora',
          cancelButtonText: 'Cancelar',
          focusConfirm: true,
          confirmButtonColor: '#E53E3E',
          iconColor: '#E53E3E',
          showCancelButton: true,
          cancelButtonColor: '#3F3F3F',
          cancelButtonAriaLabel: 'Cancelar',
          showCloseButton: true,
        })

        if (confirmed.isConfirmed) {
          for (const lead of leadsSelected) {
            try {
              await api.delete(
                `/leads/${user.tenant_id ? user.tenant_id : user.id}/${
                  lead.id
                }`,
              )

              setContactsFilter((leads) =>
                leads.filter((current) => current.id !== lead.id),
              )
              setContacts((leads) =>
                leads.filter((current) => current.id !== lead.id),
              )
            } catch (error) {
              alert('Opss, houve um erro.')
            }
          }

          setNumberOfSelectedLeads(0)
        }
      }
    }
  }

  async function handleAssignResponsibleLeads(ownerId: string) {
    try {
      const getLeadsSelected = contactsFilter.filter(
        (lead) => lead.selected === true,
      )

      for (const lead of getLeadsSelected) {
        const response = await api.put(`/leads/${lead.id}`, {
          owner_id: ownerId,
        })

        if (response.data) {
          setContactsFilter((leadsOld) =>
            leadsOld.map((currentLead) => {
              if (currentLead.id === response.data.id)
                return {
                  ...response.data,
                  selected: false,
                }
              return currentLead
            }),
          )
        }
      }
      /** Reset selected leads for close actions */
      setNumberOfSelectedLeads(0)
    } catch (error) {}
    setIsOpenAssignResponsibleLead(false)
  }

  useEffect(() => {
    async function getLeads() {
      try {
        const { data } = await api.get<ILeads[]>(`/leads`)
        if (data) {
          const leadsFormat = data.map((lead) => {
            const createdAt = format(
              parseISO(lead.created_at),
              'dd MMMM, yyyy',
              {
                locale: ptBR,
              },
            )
            return {
              ...lead,
              created_at: createdAt,
            }
          })
          setContacts(leadsFormat)
          setContactsFilter(leadsFormat)
        }
      } catch (error) {
      } finally {
        setIsLoadingLeads(false)
      }
    }
    getLeads()
  }, [])

  useEffect(() => {
    const filterContacts = contacts.filter((contact) => {
      switch (filterOwner) {
        case 'all':
          return true

        case 'my-contacts':
          return contact.owner_id === user.id

        case 'not-assigned':
          return !contact.owner_id

        default:
          return false
      }
    })
    setContactsFilter(filterContacts)
  }, [contacts, filterOwner, user.id])

  return (
    <>
      <CreateNewContact
        isOpen={isOpenCreateNewContact}
        onClose={() => setIsOpenCreateNewContact(false)}
        onRegisterContact={handleNewContact}
      />
      <AssignResponsibleLeads
        isOpen={isOpenAssinResponsibleLead}
        onClose={() => setIsOpenAssignResponsibleLead(false)}
        onSubmit={handleAssignResponsibleLeads}
      />
      <Stack
        direction={'row'}
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Stack
          direction={'row'}
          gap={2}
          alignItems="center"
          justifyContent={'center'}
        >
          <Text fontWeight="extrabold">LEADS</Text>
          <Badge variant={'outline'}>{contactsFilter.length}</Badge>
        </Stack>
        <Button
          colorScheme={'blue'}
          leftIcon={<FiPlus />}
          onClick={() => setIsOpenCreateNewContact(true)}
        >
          Novo lead
        </Button>
      </Stack>
      {/* Header filters */}
      <Stack direction={'row'} alignItems="center" mb={4}>
        <Button
          onClick={() => setFilterOwner('all')}
          colorScheme={filterOwner === 'all' ? 'blue' : 'gray'}
          size={'sm'}
        >
          Todos
        </Button>
        <Button
          onClick={() => setFilterOwner('my-contacts')}
          colorScheme={filterOwner === 'my-contacts' ? 'blue' : 'gray'}
          size={'sm'}
        >
          Meus leads
        </Button>
        <Button
          onClick={() => setFilterOwner('not-assigned')}
          colorScheme={filterOwner === 'not-assigned' ? 'blue' : 'gray'}
          size={'sm'}
        >
          Não atribuidos
        </Button>
      </Stack>

      <Stack
        direction={'row'}
        alignItems="center"
        justifyContent={'space-between'}
        bg="gray.50"
        borderWidth="1px"
        borderStyle={'solid'}
        borderColor="gray.200"
        borderRadius={'5px'}
        padding={3}
        mb={4}
        position="relative"
      >
        <Stack direction={'row'} alignItems="center">
          <InputGroup>
            <InputLeftElement pointerEvents={'none'}>
              <FiSearch />
            </InputLeftElement>
            <Input
              placeholder="Buscar pelo nome"
              onChange={handleFilterWithName}
            />
          </InputGroup>
          <Select placeholder="Status" onChange={handleFilterStatus}>
            <option value="all">Todos</option>
            <option value="new">Novo</option>
            <option value="in-progress">Em progresso</option>
            <option value="open-business">Negócio Aberto</option>
            <option value="sql">SQL - Não qualificado</option>
            <option value="mql">MQL - Qualificado</option>
            <option value="connected">Conectado</option>
            <option value="bad-time">Momento ruim</option>
          </Select>
        </Stack>
        <Stack
          direction={'row'}
          width="300px"
          alignItems="center"
          justifyContent={'flex-end'}
        >
          <Text fontSize={'12px'} color="gray.400">
            Resultado por página
          </Text>
          <Select width={'100px'}>
            <option value="5">5</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Select>
        </Stack>

        <Stack
          display={numberOfSelectedLeads > 0 ? 'flex' : 'none'}
          flexDirection="row"
          alignItems={'center'}
          position="absolute"
          w={'100%'}
          height="100%"
          background={'rgba(255,255,255,0.5)'}
          zIndex={10}
          top={0}
          left={0}
          borderRadius="10px"
          marginInlineStart={0}
          backdropFilter="blur(2px)"
          gap={4}
          padding={3}
          transition=".2s ease"
        >
          <Text textColor={'gray.600'} fontSize="sm">
            <Text as="span" fontWeight="bold">
              {numberOfSelectedLeads}
            </Text>{' '}
            selecionados
          </Text>
          <Button
            onClick={() => setIsOpenAssignResponsibleLead(true)}
            bg={'transparent'}
            color="gray.600"
            fontSize={'sm'}
            style={{ marginTop: 0 }}
            leftIcon={<FiCornerDownRight />}
          >
            Atribuir
          </Button>
          <Button
            onClick={() => {}}
            bg={'transparent'}
            color="gray.600"
            fontSize={'sm'}
            style={{ marginTop: 0 }}
            leftIcon={<FiEdit2 />}
          >
            Editar
          </Button>
          <Button
            onClick={handleDeleteLeads}
            bg={'transparent'}
            color="gray.600"
            fontSize={'sm'}
            style={{ marginTop: 0 }}
            leftIcon={<FiTrash />}
          >
            Excluir
          </Button>
        </Stack>
      </Stack>
      {/* end Header filters */}

      {contactsFilter.length > 0 || isLoadingLeads ? (
        <Table
          variant="simple"
          borderWidth="1px"
          borderStyle={'solid'}
          borderColor="gray.200"
        >
          <Thead
            bg="gray.50"
            borderWidth="1px"
            borderStyle={'solid'}
            borderColor="gray.200"
          >
            <Tr>
              <Th>
                <Checkbox onChange={handleSelectedAllLead} />
              </Th>
              <Th>Nome</Th>
              <Th>Status</Th>
              <Th>Tipo</Th>
              <Th>Proprietário do contato</Th>
              <Th>Data de criação</Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoadingLeads && (
              <>
                <ItemLeadLoading />
                <ItemLeadLoading />
              </>
            )}

            {contactsFilter.map((lead) => (
              <Tr
                key={lead.id}
                bg={'white'}
                borderWidth="1px"
                borderStyle={'solid'}
                borderColor="gray.200"
              >
                <Td px="6" width="8">
                  <Checkbox
                    onChange={() => handleSelectLead(lead.id)}
                    isChecked={lead.selected}
                  />
                </Td>
                <Td
                  display={'flex'}
                  alignItems="center"
                  gap={4}
                  border={0}
                  height={'100%'}
                >
                  <Avatar
                    src={
                      lead.avatar
                        ? `${process.env.REACT_APP_AWS_BUCKET_URL}/avatar/${lead.avatar}`
                        : undefined
                    }
                    size={'md'}
                    name={lead.name}
                  />
                  <span>
                    <Link to={`/dashboard/leads/${lead.id}`}>
                      <Text fontWeight="medium">{lead.name}</Text>
                    </Link>
                  </span>
                </Td>
                <Td>
                  <Tag size={'md'} variant="solid" colorScheme="green">
                    {statusLabels[lead.status]}
                  </Tag>
                </Td>
                <Td>
                  {lead.type ? (
                    <Tag
                      size={'md'}
                      variant="solid"
                      colorScheme={typesLabels[lead.type].color}
                    >
                      {typesLabels[lead.type].text}
                    </Tag>
                  ) : (
                    '--'
                  )}
                </Td>
                {/* Owner */}
                <Td
                  display={'flex'}
                  alignItems="center"
                  height={'100%'}
                  gap={4}
                  borderBottom="0"
                >
                  {lead.owner ? (
                    <Avatar
                      src={
                        lead.owner.avatar
                          ? `${process.env.REACT_APP_AWS_BUCKET_URL}/avatar/${lead.owner.avatar}`
                          : undefined
                      }
                      size={'sm'}
                      name={`${lead.owner.first_name} ${lead.owner.last_name}`}
                    />
                  ) : (
                    <Avatar size={'sm'} />
                  )}
                  <span>
                    <Link to={`/dashboard/contacts/${lead.id}`}>
                      <Text fontWeight="medium">
                        {lead.owner
                          ? `${lead.owner.first_name} ${lead.owner.last_name}`
                          : 'Não atribuído '}
                      </Text>
                    </Link>
                  </span>
                </Td>
                {/* Owner */}
                <Td>{lead.created_at}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          bg="gray.200"
          p={4}
        >
          <Text color="gray.500">Nenhum lead foi encontrado</Text>
        </Box>
      )}
    </>
  )
}

function ItemLeadLoading() {
  return (
    <Tr
      bg={'white'}
      borderWidth="1px"
      borderStyle={'solid'}
      borderColor="gray.200"
    >
      <Td px="6" width="8">
        <Skeleton h="16px" w="16px" />
      </Td>
      <Td
        display={'flex'}
        alignItems="center"
        gap={4}
        border={0}
        height={'100%'}
      >
        <SkeletonCircle h="48px" w="48px" />
        <Skeleton h="16px" w="50%" />
      </Td>
      <Td>
        {' '}
        <Skeleton h="16px" />{' '}
      </Td>
      <Td>
        {' '}
        <Skeleton h="16px" />{' '}
      </Td>
      {/* Owner */}
      <Td
        display={'flex'}
        alignItems="center"
        height={'100%'}
        gap={4}
        borderBottom="0"
      >
        <SkeletonCircle h="32px" w="32px" />
        <Skeleton h="16px" w="50%" />
      </Td>
      {/* Owner */}
      <Td>
        {' '}
        <Skeleton h="16px" />{' '}
      </Td>
    </Tr>
  )
}
