import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { api } from '../utils/api'

interface ICredits {
  amount: number
  amount_currency: string
}

export function useCredits() {
  const [credits, setCredits] = useState<ICredits | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    async function getCredits() {
      const { data } = await api.get('/calls/credit')

      setCredits({
        amount: data.amount,
        amount_currency: new Intl.NumberFormat('pt-BR', {
          currency: 'BRL',
          style: 'currency',
        }).format(data.amount),
      })
    }
    getCredits()
  }, [])

  async function addCredits(amount: number) {
    try {
      setIsSubmitting(true)
      const convertValue = amount * 100

      const { data } = await api.post('/calls/credit', {
        amount: convertValue,
      })
      navigate(`/dashboard/checkout-credits/${data.client_secret}`)
    } catch (error) {
    } finally {
      setIsSubmitting(false)
    }
  }

  return {
    credits,
    addCredits,
    isSubmitting,
  }
}
