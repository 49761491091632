import { useState } from 'react'
import { Button, InputGroup, InputLeftElement } from '@chakra-ui/react'
import * as Popover from '@radix-ui/react-popover'
import { useCredits } from '../../../../hooks/useCredits'

import { InputReal } from '../../../../components/InputReal'
import {
  AddCreditsContainer,
  AvailableCredits,
  PopoverArrow,
  PopoverContent,
} from './styles'

export function Credits() {
  const { addCredits, isSubmitting, credits } = useCredits()
  const [amount, setAmount] = useState(0)

  function handleAddCredits() {
    if (!amount) return
    addCredits(amount)
  }

  return (
    <Popover.Portal>
      <PopoverContent>
        <Popover.Close />
        <PopoverArrow />

        <div>
          <AvailableCredits>
            <span>Créditos disponíveis</span>
            <strong>{credits ? credits.amount_currency : 'R$ 0,00'}</strong>
          </AvailableCredits>
          <AddCreditsContainer>
            <strong>Adicionar créditos</strong>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                color="gray.300"
                fontSize="1.2em"
              >
                R$
              </InputLeftElement>
              <InputReal
                onChangeValue={(value) => setAmount(value)}
                maxWidth="240px"
                placeholder="20,00"
                _placeholder={{
                  color: 'gray.300',
                }}
                pl={8}
              />
            </InputGroup>
            <Button
              colorScheme="green"
              onClick={handleAddCredits}
              isLoading={isSubmitting}
            >
              Adicionar
            </Button>
          </AddCreditsContainer>
        </div>
      </PopoverContent>
    </Popover.Portal>
  )
}
