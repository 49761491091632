import {
  Box,
  Table,
  Text,
  Tr,
  Th,
  Thead,
  Checkbox,
  Tbody,
  Td,
  Input,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useAuth } from '../../../../../hooks/Auth'
import { api } from '../../../../../utils/api'
import { IProducts } from '../../../../Products'

interface ProductsFormat extends IProducts {
  amount_format: string
  isSelected?: boolean
}

export interface ISelectedProduct extends ProductsFormat {
  quantity: number
  total?: string
}

interface Props {
  isVisible?: boolean
  onChangeValue: (values: ISelectedProduct[]) => void
}

export function StepTwo({ onChangeValue, isVisible = false }: Props) {
  const { user } = useAuth()
  const [products, setProducts] = useState<ProductsFormat[]>([])
  const [selectedProducts, setSelectedProducts] = useState<ISelectedProduct[]>(
    [],
  )
  const [total, setTotal] = useState('R$ 0')

  function handleSelectedItem(id: string) {
    const product = products.find((product) => product.id === id)
    const checkAlreadyOnTheList = selectedProducts.find(
      (product) => product.id === id,
    )

    if (checkAlreadyOnTheList) {
      setProducts((prev) =>
        prev.map((productCurrent) => {
          if (productCurrent.id === id) productCurrent.isSelected = false
          return productCurrent
        }),
      )

      const updateSelectedProducts = selectedProducts.filter(
        (product) => product.id !== id,
      )
      setSelectedProducts(updateSelectedProducts)
      onChangeValue(updateSelectedProducts)
    } else {
      if (product) {
        setProducts((prev) =>
          prev.map((productCurrent) => {
            if (productCurrent.id === id) productCurrent.isSelected = true
            return productCurrent
          }),
        )

        const productFormat = {
          ...product,
          quantity: product.suggested_amount ? product.suggested_amount : 1,
          total: new Intl.NumberFormat('pt-BR').format(
            product.suggested_amount
              ? product.suggested_amount * product.amount
              : product.amount,
          ),
        }

        const updateSelectedProducts = [...selectedProducts, productFormat]
        setSelectedProducts(updateSelectedProducts)
        onChangeValue(updateSelectedProducts)
      }
    }
  }

  function handleChangeQuantityItem(id: string, value: string) {
    const product = selectedProducts.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          quantity: Number(value),
          total: new Intl.NumberFormat('pt-BR').format(
            Number(value) * item.amount,
          ),
        }
      }
      return item
    })
    setSelectedProducts(product)
  }

  useEffect(() => {
    ;(async () => {
      const { data } = await api.get<IProducts[]>(`/products`)
      if (data) {
        const format = data.map((product) => ({
          ...product,
          amount_format: new Intl.NumberFormat('pt-BR').format(product.amount),
        }))
        setProducts(format)
      }
    })()
  }, [user])

  useEffect(() => {
    if (selectedProducts.length > 0) {
      const total = selectedProducts.reduce((accumulator, currentProduct) => {
        return (accumulator +=
          currentProduct.quantity * Number(currentProduct.amount))
      }, 0)
      setTotal(new Intl.NumberFormat('pt-BR').format(total))
    } else {
      setTotal(new Intl.NumberFormat('pt-BR').format(0))
    }
  }, [selectedProducts])

  return (
    <Box
      display={isVisible ? 'grid' : 'none'}
      gridTemplateColumns="1fr 1fr"
      gap={8}
      flexDirection={'row'}
      w="100%"
      mt={8}
    >
      <Box>
        <Text fontWeight="bold" fontSize={'sm'} mb={2}>
          Itens disponíveis
        </Text>
        <Box
          w={'100%'}
          height="408px"
          background={'gray.50'}
          borderWidth="1px"
          borderStyle={'solid'}
          borderColor="gray.200"
          overflowY={'auto'}
        >
          <Table>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Descrição</Th>
                <Th>Unidade de medida</Th>
                <Th>Valor</Th>
              </Tr>
            </Thead>
            <Tbody background={'white'}>
              {products.map((product) => (
                <Tr
                  key={product.id}
                  background={product.isSelected ? 'brand.50' : 'white'}
                >
                  <Td>
                    <Checkbox
                      onChange={() => handleSelectedItem(product.id)}
                      isChecked={product.isSelected}
                    />
                  </Td>
                  <Td>{product.description}</Td>
                  <Td>{product.unit_of_measure}</Td>
                  <Td>{product.amount_format}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
      <Box>
        <Text fontWeight="bold" fontSize={'sm'} mb={2}>
          Itens no orçamento
        </Text>
        <Box
          w={'100%'}
          height="408px"
          background={'gray.50'}
          borderWidth="1px"
          borderStyle={'solid'}
          borderColor="gray.200"
          position={'relative'}
        >
          <Table>
            <Thead>
              <Tr>
                <Th borderBottomWidth={'1px'} borderBottomColor="gray.200">
                  Descrição
                </Th>
                <Th borderBottomWidth={'1px'} borderBottomColor="gray.200">
                  Unidade de medida
                </Th>
                <Th borderBottomWidth={'1px'} borderBottomColor="gray.200">
                  Valor
                </Th>
                <Th borderBottomWidth={'1px'} borderBottomColor="gray.200">
                  Quantidade
                </Th>
                <Th borderBottomWidth={'1px'} borderBottomColor="gray.200">
                  Total
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {selectedProducts.length > 0 ? (
                selectedProducts.map((product) => (
                  <Tr key={product.id} background={'white'}>
                    <Td>{product.description}</Td>
                    <Td>{product.unit_of_measure}</Td>
                    <Td>{product.amount_format}</Td>
                    <Td>
                      <Input
                        size={'sm'}
                        value={product.quantity}
                        onChange={(event) =>
                          handleChangeQuantityItem(
                            product.id,
                            event.target.value,
                          )
                        }
                      />
                    </Td>
                    <Td>{product.total}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td
                    textAlign={'center'}
                    colSpan={5}
                    padding={8}
                    color={'gray.300'}
                  >
                    Nenhum produto selecionado
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>

          <Box
            w={'100%'}
            position={'absolute'}
            bottom={'0'}
            paddingX={5}
            paddingY={2}
            borderTopWidth={'1px'}
            borderTopColor="gray.200"
            display={'flex'}
            justifyContent="flex-end"
          >
            <Text as={'p'} fontSize="sm" display={'flex'} fontWeight="bold">
              TOTAL{' '}
              <Text as={'span'} marginLeft={2} fontSize="sm">
                {total}
              </Text>
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
