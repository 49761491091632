import { useNavigate } from 'react-router-dom'
import { isBefore, parseISO } from 'date-fns'
import {
  Box,
  Button,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { Avatar } from '../../components/Avatar'
import { Timeline } from '../../components/Timeline'
import {
  FiArrowLeft,
  FiMoreHorizontal,
  FiEdit,
  FiTrash2,
  FiExternalLink,
  FiClipboard,
  FiCalendar,
} from 'react-icons/fi'

import { CreateNewEmail } from './components/CreateNewEmail'
import { CreateProposals } from './components/CreateProposals'
import { Details } from './components/TabPanels/Details'
import { EditLead } from './components/EditLead'
import { NotResult } from '../../components/NotResult'
import { CreateTask } from './components/CreateTask'
import { useLeads } from '../../hooks/useLeads'
import { Historics } from './components/TabPanels/Historics'

export function Lead() {
  const navigate = useNavigate()

  const {
    lead,
    isLoading,
    dialogCreateNewEmail,
    dialogCreateProposals,
    dialogCreateTask,
    dialogEditLead,
    proposals,
    handleDeleteLead,
    handleNewEmail,
    handleOnUpdateLead,
    handleSubmitUpdateAvatar,
    historicsEmails,
    onCreateNewProposal,
  } = useLeads()

  if (!lead && isLoading) {
    return (
      <Stack flex={1} justifyItems="center" alignContent="center">
        <Spinner />
      </Stack>
    )
  }

  return (
    <>
      {/* Modals */}
      <EditLead
        isOpen={dialogEditLead.isOpen}
        lead={lead}
        onClose={dialogEditLead.onClose}
        onUpdateLead={handleOnUpdateLead}
      />
      <CreateTask
        isOpen={dialogCreateTask.isOpen}
        onClose={dialogCreateTask.onClose}
        onCreateTask={console.log}
        leadId={lead ? lead.id : ''}
      />
      {/* end Modals */}
      <Button
        size={'sm'}
        mb={4}
        leftIcon={<FiArrowLeft />}
        onClick={() => navigate(-1)}
      >
        Voltar
      </Button>
      <Stack display={'flex'} flexDirection="row" gap={4} width={'100%'} mb={8}>
        <Avatar
          name={lead!.name}
          src={
            lead!.avatar
              ? `${process.env.REACT_APP_AWS_BUCKET_URL}/avatar/${lead!.avatar}`
              : ''
          }
          size="xl"
          onSubmit={handleSubmitUpdateAvatar}
        />
        <Stack
          direction={'row'}
          alignItems="center"
          justifyContent={'space-between'}
          flex="1"
        >
          <Stack direction={'row'} gap={4} alignItems="center">
            <Stack>
              <Text fontSize={'sm'} color="brand.500">
                {lead!.sector}
              </Text>
              <Text
                as={'h2'}
                fontWeight="bold"
                fontSize={'lg'}
                color="brand.300"
                lineHeight={1}
              >
                {lead!.name}
              </Text>
              <Text fontSize={'sm'} color="brand.500">
                {lead!.website ? lead!.website : 'Sem website'}
              </Text>
            </Stack>
            <Menu>
              <MenuButton
                as={'button'}
                px={4}
                py={2}
                transition="all 0.2s"
                borderRadius="md"
                borderWidth="medium"
                color={'brand.300'}
                height={'24px'}
                _hover={{
                  bg: 'brand.500',
                  color: 'white',
                  borderColor: 'brand.500',
                }}
                _expanded={{ bg: 'brand.300', color: 'white' }}
              >
                <FiMoreHorizontal size={16} />
              </MenuButton>
              <MenuList>
                <MenuGroup title="Orçamento">
                  {/* <MenuItem gap={2} onClick={() => {}}>
                                        <FiFileText /> Upload de documento
                                    </MenuItem> */}
                  <MenuItem gap={2} onClick={dialogCreateProposals.onOpen}>
                    <FiClipboard /> Criar proposta
                  </MenuItem>
                </MenuGroup>
                <MenuDivider />
                <MenuGroup title="Ações">
                  <MenuItem gap={2} onClick={dialogEditLead.onOpen}>
                    <FiEdit /> Editar
                  </MenuItem>
                  <MenuItem gap={2} onClick={dialogCreateTask.onOpen}>
                    <FiCalendar /> Criar tarefa
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem gap={2} color="red.400" onClick={handleDeleteLead}>
                    <FiTrash2 /> Deletar
                  </MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
          </Stack>
        </Stack>
      </Stack>

      <Tabs>
        <TabList>
          <Tab>Detalhes</Tab>
          <Tab>Atividades</Tab>
          <Tab>Emails</Tab>
          {/* <Tab>Anexos</Tab> */}
          <Tab>Propostas</Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={'16px 0px'}>
            <Details lead={lead!} />
          </TabPanel>
          {/* Historics */}
          <TabPanel p={'16px 0px'}>
            <Historics />
          </TabPanel>
          {/* Emails */}
          <TabPanel>
            <Stack display={'flex'} alignItems="end">
              <Button
                colorScheme={'blue'}
                onClick={dialogCreateNewEmail.onOpen}
              >
                Enviar e-mail
              </Button>
            </Stack>
            <CreateNewEmail
              leadId={lead!.id}
              onClose={dialogCreateNewEmail.onClose}
              isOpen={dialogCreateNewEmail.isOpen}
              onSendMail={handleNewEmail}
            />
            {historicsEmails.length > 0 ? (
              <Timeline data={historicsEmails} />
            ) : (
              <p>Nenhum e-mail foi enviado para este contato.</p>
            )}
          </TabPanel>
          {/* Documents */}
          {/* <TabPanel>
                        {historicsEmails.length > 0 ? (
                            <Timeline data={historicsEmails} />
                        ) : (
                            <NotResult 
                                icon={FiFileText}
                                title="Nenhum documento foi encontrada." 
                            />
                        )}
                    </TabPanel> */}
          {/* Proposals */}
          <TabPanel>
            <CreateProposals
              lead={lead!}
              onClose={dialogCreateProposals.onClose}
              onNewCreateProposal={onCreateNewProposal}
              isOpen={dialogCreateProposals.isOpen}
            />
            {proposals.length > 0 ? (
              <List>
                {proposals.map((proposal) => (
                  <ListItem
                    key={proposal.id}
                    w={'100%'}
                    bg={'gray.50'}
                    mb={4}
                    display="flex"
                    borderWidth="1px"
                    borderStyle={'solid'}
                    borderColor="gray.200"
                    borderRadius={'10px'}
                    py={4}
                    px={4}
                  >
                    <Box flex={1}>
                      <Text color={'brand.500'} fontSize="small">
                        Título da proposta
                      </Text>
                      <Text size="md" fontWeight="medium">
                        {proposal.title}
                      </Text>
                      <Box
                        display={'grid'}
                        gridTemplateColumns={'1fr 1fr 1fr'}
                        gap={4}
                        mt={4}
                      >
                        <Box>
                          <Text color={'brand.500'} fontSize="small">
                            Valor da proposta
                          </Text>
                          <Text color="brand.100" fontWeight="medium">
                            {proposal.amount}
                          </Text>
                        </Box>
                        <Box>
                          <Text color={'brand.500'} fontSize="small">
                            Data da criação
                          </Text>
                          <Text>{proposal.created_at}</Text>
                        </Box>
                        <Box>
                          <Text color={'brand.500'} fontSize="small">
                            Data de expiracão
                          </Text>

                          <Text
                            color={
                              isBefore(
                                parseISO(proposal.expire_date),
                                Date.now(),
                              )
                                ? 'red.400'
                                : 'gray.600'
                            }
                          >
                            {proposal.expire_date}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Button
                        as={'a'}
                        href={proposal.proposal_url}
                        target="_blank"
                        title="Visualizar proposta"
                      >
                        <FiExternalLink />
                      </Button>
                    </Box>
                  </ListItem>
                ))}
              </List>
            ) : (
              <NotResult
                icon={FiClipboard}
                title="Nenhuma proposta foi encontrada."
              />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}
