import styled, { keyframes } from 'styled-components'
import * as Popover from '@radix-ui/react-popover'

const scaleIn = keyframes`
    0% { 
        opacity: 0; 
        transform: scale(0);
    }

    100% { 
        opacity: 1; 
        transform: scale(1);
    }
`

export const PopoverArrow = styled(Popover.Arrow)`
  fill: white;
`

export const PopoverContent = styled(Popover.Content)`
  transform-origin: var(--radix-popover-content-transform-origin);
  transform: translate(-10%);
  animation: ${scaleIn} 0.3s ease-out;
  padding: 1.5rem 2rem;
  background: white;
  border-radius: 6px;
  box-shadow: 0 4px 20px 0 rgb(25 25 34/24%);
  z-index: 99;
  margin-top: 1rem;
`

export const AvailableCredits = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  color: #2d3748;
  font-size: 0.75rem;

  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e2e8f0;
`

export const AddCreditsContainer = styled.div`
  color: #2d3748;
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
