import { AnimationContainer, Container, Content } from './styles'
import Lottie from 'react-lottie'
import { FiCheckCircle } from 'react-icons/fi'

import animationData from './congratulations-animation.json'
import { Button } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const lottieOptions = {
  loop: false,
  autoPlay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

export function CheckoutSucess() {
  return (
    <Container>
      <AnimationContainer>
        <Lottie options={lottieOptions} height={'100%'} width={'100%'} />
      </AnimationContainer>
      <Content>
        <FiCheckCircle size={64} color="#00B37E" />
        <h1>Parabéns!</h1>
        <p>
          Pagamento realizado com sucesso, você pode consultar suas transações
          <br />
          em configuração e pagamentos!
        </p>
        <Link to={'/dashboard'}>
          <Button as="p">Voltar</Button>
        </Link>
      </Content>
    </Container>
  )
}
