import { RawDraftContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { Editor as EditorDraft } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import styles from './styles.module.scss'

type EditorProps = {
  onChange?: (content: string) => void
}

export function Editor({ onChange }: EditorProps) {
  function handleOnChange(contentState: RawDraftContentState) {
    if (onChange) {
      const content = draftToHtml(contentState)
      onChange(content)
    }
  }

  return (
    <EditorDraft
      localization={{
        locale: 'pt',
      }}
      editorClassName={styles.editor}
      toolbarClassName={styles.toolbar}
      onChange={handleOnChange}
      toolbar={{
        options: ['inline', 'list'],
        inline: {
          options: ['bold', 'italic', 'underline', 'strikethrough'],
          bold: { className: styles.button },
          italic: { className: styles.button },
          underline: { className: styles.button },
          strikethrough: { className: styles.button },
        },
        list: {
          options: ['unordered', 'ordered'],
          unordered: { className: styles.button },
          ordered: { className: styles.button },
        },
      }}
    />
  )
}
