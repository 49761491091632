import React, { useState, useRef } from 'react'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { FiFileText } from 'react-icons/fi'

import styles from './styles.module.scss'
import { humanFileSize } from '../../../utils/formatSizeFile'
import { api } from '../../../utils/api'

const fileTypes = [
  'docx',
  'pdf',
  'vnd.openxmlformats-officedocument.wordprocessingml.document',
]

export interface IProposalTemplate {
  id: string
  name: string
  filename: string
  template_url: string
  size: string
  tenant_id: string
  created_at: string
  updated_at: string
}

interface UploadTemplateProps {
  isOpen: boolean
  onClose: () => void
  onSaveTemplate: (template: IProposalTemplate) => void
}

export function UploadTemplate({
  isOpen,
  onClose,
  onSaveTemplate,
}: UploadTemplateProps) {
  const toast = useToast()
  const inputRef = useRef<HTMLInputElement>(null)
  const [isOnTheBox, setIsOnTheBox] = useState(false)
  const [isLoadingRequest, setIsLoadingRequest] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [name, setName] = useState('')

  function handleChange(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault()

    setIsOnTheBox(false)

    if (event.dataTransfer.items) {
      if (event.dataTransfer.items.length > 1) {
        return toast({
          status: 'error',
          title: 'Apenas um arquivo por vez',
          position: 'top-right',
        })
      }
      const file = event.dataTransfer.items[0].getAsFile()

      const checkTypeFile = fileTypes.includes(file!.type.split('/')[1])

      if (!checkTypeFile)
        return toast({
          status: 'error',
          title: 'Este formato de arquivo não é permitido',
          description: 'Formatos permitidos são PDF E DOCX',
          position: 'top-right',
        })

      setFile(file)
    }
  }

  function dragOverHandler(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault()
    setIsOnTheBox(true)
  }

  function onDragLeave(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault()
    setIsOnTheBox(false)
  }

  function handleOpenInputFile() {
    if (file) return
    inputRef.current?.click()
  }

  function handleChangeInputFile(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault()
    if (event.target.files) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0]
        const checkTypeFile = fileTypes.includes(file.type.split('/')[1])

        if (!checkTypeFile)
          return toast({
            status: 'error',
            title: 'Este formato de arquivo não é permitido',
            description: 'Formatos permitidos são PDF E DOCX',
            position: 'top-right',
          })

        setFile(file)
      }
    }
  }

  async function handleSubmit() {
    if (file && !!name) {
      try {
        setIsLoadingRequest(true)
        const formdata = new FormData()
        formdata.append('template', file)

        const { data } = await api.post<IProposalTemplate>(
          `/proposals/template?name=${name}`,
          formdata,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        if (data) onSaveTemplate(data)
      } catch (error) {
      } finally {
        setIsLoadingRequest(false)
        setFile(null)
        onClose()
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent>
        <ModalHeader background={'brand.100'} color="white">
          Criar novo modelo de proposta
        </ModalHeader>
        <ModalCloseButton color={'gray.200'} />

        <ModalBody pt={4}>
          <Input
            name="name"
            onChange={(event) => setName(event.target.value)}
            placeholder="Nome do modelo"
            mb={4}
          />

          <Stack
            className={styles.container}
            onDrop={handleChange}
            onDragOver={dragOverHandler}
            onDragLeave={onDragLeave}
            transition="ease .2s"
            background={isOnTheBox ? 'gray.100' : 'transparent'}
            direction="column"
            justifyContent={'center'}
            alignItems="center"
            borderWidth={'2px'}
            borderStyle="dashed"
            borderColor={isOnTheBox ? 'blue.500' : 'gray.400'}
            cursor={file ? 'initial' : 'pointer'}
            onClick={handleOpenInputFile}
          >
            <input
              onChange={handleChangeInputFile}
              type="file"
              name="file"
              id="file"
              style={{ display: 'none' }}
              ref={inputRef}
            />
            {!file ? (
              <>
                <FiFileText
                  size={64}
                  color={isOnTheBox ? '#3F6EF0' : '#4A5568'}
                />
                <Text
                  fontSize={'lg'}
                  color={isOnTheBox ? '#3F6EF0' : 'gray.600'}
                  lineHeight={1}
                  fontWeight="bold"
                >
                  Selecione o modelo para upload
                </Text>
                <Text fontSize={'sm'} color="gray.400">
                  ou arraste e solte o arquivo nesta zona.
                </Text>
              </>
            ) : (
              <>
                <FiFileText size={64} color="#3F6EF0" />
                <Text
                  fontSize={'lg'}
                  color="#3F6EF0"
                  lineHeight={1}
                  fontWeight="bold"
                >
                  {file.name}
                </Text>
                <Text fontSize={'sm'} color="gray.400">
                  {humanFileSize(file.size, true)}
                </Text>
                <button className={styles.button} onClick={() => setFile(null)}>
                  Cancelar
                </button>
              </>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Fechar</Button>
          <Button
            onClick={handleSubmit}
            ml={4}
            bgColor={'brand.100'}
            color={'white'}
            _hover={{
              bg: 'brand.100',
              color: 'white',
              filter: 'brightness(.8)',
            }}
            disabled={!(!!file && !!name)}
            isLoading={isLoadingRequest}
          >
            Salvar novo modelo
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
