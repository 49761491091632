import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import Select from 'react-select'
import { api } from '../../../utils/api'
import { ILeads } from '..'
import { SelectOriginLead } from './SelectOriginLead'

interface Props {
  isOpen?: boolean
  onClose: () => void
  onRegisterContact: (contact: ILeads) => void
}

interface ReactSelectData {
  value: string
  label: string
}

const optionsStatus = [
  { value: 'new', label: 'Novo' },
  { value: 'in-progress', label: 'Em andamento' },
  { value: 'open-business', label: 'Negocio aberto' },
  { value: 'unqualified', label: 'Não qualificado' },
  { value: 'bad-time', label: 'Momento ruim' },
]

export function CreateNewContact({
  onClose,
  isOpen = false,
  onRegisterContact,
}: Props) {
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  })
  const [users, setUsers] = useState<ReactSelectData[]>([])
  const [origins, setOrigins] = useState<ReactSelectData[]>([])
  const [originSelected, setOriginSelected] = useState<
    ReactSelectData | undefined
  >(undefined)

  const [typeContact, setTypeContact] = useState('company')
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingSelectOrigin, setIsLoadingSelectOrigin] = useState(true)

  const formik = useFormik({
    initialValues: {
      name: '',
      owner_id: undefined,
      status: '',
      sector: '',
      number_employees: '',
    },
    onSubmit: async (values) => {
      const data = {
        ...values,
        type: typeContact,
        origin_id: originSelected ? originSelected.value : undefined,
      }

      try {
        setIsLoading(true)

        const schema = Yup.object({
          name: Yup.string().required('Nome é obrigatório'),
          owner_id: Yup.string().nullable(true),
          status: Yup.string(),
          origin_id: Yup.string().nullable(true),
          phone: Yup.string(),
          type: Yup.string(),
          sector: Yup.string(),
          number_employees: Yup.string(),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        const response = await api.post(`/leads`, data)

        onRegisterContact(response.data)
        onClose()

        formik.resetForm()

        toast({
          title: 'Lead cadastrado com sucesso!',
          status: 'success',
        })
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          console.log({ error })
          return toast({
            title: 'Dados obrigatórios não preenchidos',
            description: 'Verifique os dados incompletos.',
            status: 'error',
          })
        }

        toast({
          title: 'Ops, houve um error inesperado',
          status: 'error',
        })
      } finally {
        setIsLoading(false)
      }
    },
  })

  async function handleNewOrigin(value: string) {
    try {
      setIsLoadingSelectOrigin(true)
      const { data } = await api.post('/settings/lead-origin', {
        name: value,
      })

      const newOrigin = { value: data.id, label: data.name }

      setOrigins([...origins, newOrigin])

      setOriginSelected(newOrigin)
    } catch (error) {
      toast({
        title: 'Houve um erro ao cadastrar',
        description: 'Tente novamente mais tarde.',
      })
    } finally {
      setIsLoadingSelectOrigin(false)
    }
  }

  function handleSelectOrigin(value: ReactSelectData) {
    setOriginSelected(value)
  }
  useEffect(() => {
    async function getUsers() {
      const { data } = await api.get<ILeads[]>(`/users`)

      const usersFormat = data.map((user: any) => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
      }))

      setUsers(usersFormat)
    }
    async function getOrigins() {
      const { data } = await api.get('/settings/lead-origin')
      const formatdata = data.map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
      setOrigins(formatdata)
      setIsLoadingSelectOrigin(false)
    }
    getUsers()
    getOrigins()
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent>
        <ModalHeader background={'brand.100'} color="white">
          Criar novo lead
        </ModalHeader>
        <ModalCloseButton color={'gray.200'} />

        <ModalBody pt={4}>
          <form onSubmit={formik.handleSubmit}>
            <Stack mb={4}>
              <Text fontSize={'12px'} color={'brand.500'}>
                Nome{' '}
                <Text as={'span'} color={'red.500'}>
                  *
                </Text>
              </Text>
              <Input
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Nome"
              />
            </Stack>
            <Stack mb={4}>
              <Text fontSize={'12px'} color={'brand.500'}>
                Responsável pelo lead
              </Text>
              <Select
                options={users}
                name="owner_id"
                placeholder="Responsável pelo lead"
                onChange={(value) =>
                  formik.setFieldValue('owner_id', value?.value)
                }
              />
            </Stack>
            <Stack
              display={'grid'}
              gridTemplateColumns="1fr 1fr"
              alignItems={'flex-end'}
              gap={4}
              mt={4}
            >
              <Stack>
                <Text fontSize={'12px'} color={'brand.500'}>
                  Status
                </Text>
                <Select
                  options={optionsStatus}
                  name="status"
                  placeholder="Status"
                  onChange={(value) =>
                    formik.setFieldValue('status', value?.value)
                  }
                />
              </Stack>
              <Stack>
                <Text fontSize={'12px'} color={'brand.500'}>
                  Origem
                </Text>
                <SelectOriginLead
                  options={origins}
                  value={originSelected}
                  isLoading={isLoadingSelectOrigin}
                  onCreateNewOrigin={handleNewOrigin}
                  onSelected={handleSelectOrigin}
                  onClear={() => setOriginSelected(undefined)}
                />
              </Stack>
            </Stack>
            <Stack
              display={'grid'}
              gridTemplateColumns="1fr 1fr"
              alignItems={'flex-end'}
              gap={4}
              mt={4}
            >
              <Stack>
                <Text fontSize={'12px'} color={'brand.500'}>
                  Setor
                </Text>
                <Input
                  name="sector"
                  onChange={formik.handleChange}
                  value={formik.values.sector}
                  placeholder="Construtora"
                />
              </Stack>
              <Stack>
                <Text fontSize={'12px'} color={'brand.500'}>
                  Número de funcionários
                </Text>
                <Input
                  name="number_employees"
                  onChange={formik.handleChange}
                  value={formik.values.number_employees}
                  placeholder="5"
                />
              </Stack>
            </Stack>
            <Stack
              display={'grid'}
              gridTemplateColumns="1fr 1fr"
              alignItems={'flex-end'}
              gap={4}
              mt={4}
            >
              <Stack>
                <Text fontSize={'14px'} color={'gray.400'}>
                  Tipo do contato:
                </Text>
                <RadioGroup onChange={setTypeContact} value={typeContact}>
                  <Stack spacing={5} direction="row">
                    <Radio value={'project'}>Projeto/Obra</Radio>
                    <Radio value={'company'}>Empresa</Radio>
                  </Stack>
                </RadioGroup>
              </Stack>
            </Stack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Fechar</Button>
          <Button
            onClick={formik.submitForm}
            ml={4}
            bgColor={'brand.100'}
            color={'white'}
            _hover={{
              bg: 'brand.100',
              color: 'white',
              filter: 'brightness(.8)',
            }}
            isLoading={isLoading}
          >
            Criar contato
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
