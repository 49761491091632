import { Avatar } from '@chakra-ui/react'
import {
  FiActivity,
  FiBook,
  FiCalendar,
  FiMail,
  FiPhone,
  FiUserPlus,
  FiRefreshCw,
  FiPhoneCall,
} from 'react-icons/fi'
import { IHistory, IProposals } from '../../hooks/useLeads'
import { ItemTimeline } from '../ItemTimeline'
import styles from './styles.module.scss'

interface Props {
  data?: IHistory[]
}

const typesHistoric = {
  activity: {
    label: 'Atividade',
    complement: ' realizada por ',
    icon: <FiActivity size={12} />,
  },
  notes: {
    label: 'Anotação',
    complement: ' realizada por ',
    icon: <FiBook size={12} />,
  },
  email: {
    label: 'Email',
    complement: ' enviado por ',
    icon: <FiMail size={12} />,
  },
  schedule: {
    label: 'Lembrete',
    complement: ' realizado por ',
    icon: <FiCalendar size={12} />,
  },
  contact: {
    label: 'Ligação',
    complement: ' realizado por ',
    icon: <FiPhone size={12} />,
  },
  created: {
    label: 'Lead criado ',
    complement: '',
    icon: <FiUserPlus size={12} />,
  },
  updated: {
    label: 'Atividade',
    complement: ' realizada por ',
    icon: <FiActivity size={12} />,
  },
  'changed-owner': {
    label: 'Atividade',
    complement: ' realizada por ',
    icon: <FiRefreshCw size={12} />,
  },
  call: {
    label: 'Atividade',
    complement: ' realizada por ',
    icon: <FiPhoneCall size={12} />,
  },
  task: {
    label: 'Tarefa',
    complement: ' Agendada por ',
    icon: <FiCalendar size={12} />,
  },
}

export function Timeline({ data }: Props) {
  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {data &&
          data.map((history) => (
            <li key={history.id}>
              <div className={styles.type}>
                {typesHistoric[history.type].icon}
              </div>
              <div className={styles.info}>
                <Avatar
                  name={`${history.owner?.first_name} ${history.owner?.last_name}`}
                  src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${history.owner?.avatar}`}
                  size={'sm'}
                />
                {history.type === 'changed-owner' ? (
                  <p>
                    <strong>
                      {`${history.owner?.first_name} ${history.owner?.last_name}`}{' '}
                    </strong>
                    {/* <strong>{typesHistoric[history.type].label}</strong>{typesHistoric[history.type].complement} */}
                    {history.title} em {history.created_at}
                  </p>
                ) : (
                  <p>
                    <strong>{typesHistoric[history.type].label}</strong>
                    {typesHistoric[history.type].complement}
                    {history.owner && (
                      <strong>{`${history.owner?.first_name} ${history.owner?.last_name}`}</strong>
                    )}{' '}
                    em {history.created_at}
                  </p>
                )}
              </div>
              {history.type === 'call' && history.call && (
                <ItemTimeline data={history.call} />
              )}
              {history.content && history.type !== 'changed-owner' && (
                <div
                  className={styles.content}
                  dangerouslySetInnerHTML={{
                    __html: history.content,
                  }}
                />
              )}
            </li>
          ))}
      </ul>
    </div>
  )
}
interface TimelineProposalsProps {
  data: IProposals[]
}
export function TimelineProposals({ data }: TimelineProposalsProps) {
  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {data.map((proposal) => (
          <li key={proposal.id}>
            <div className={styles.content}>
              <strong>{proposal.proposal_url}</strong>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
