import { Button, Input, Stack, Text, useToast } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { api } from '../../../utils/api'
import * as Yup from 'yup'

type SMTPProps = {
  id: string
  smtp: string
  email: string
  tenant_id: string
  user_id: string
  created_at: string
  updated_at: string
}

export function Emails() {
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  })

  const formik = useFormik({
    initialValues: {
      smtp: '',
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        const schema = Yup.object().shape({
          smtp: Yup.string().required('Servidor SMTP é obrigatório'),
          email: Yup.string().email('E-mail informado não parece válido'),
          password: Yup.string().required('Senha é obrigatório'),
        })

        await schema.validate(values, { abortEarly: false })

        await api.post<SMTPProps>('/smtp', values)

        toast({
          title: 'SMTP cadastrado com sucesso',
          status: 'success',
        })
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          toast({
            title: 'Verifique os dados obrigatórios',
            status: 'error',
          })
        }
      } finally {
        setIsLoading(false)
      }
    },
  })

  useEffect(() => {
    async function getSMTP() {
      const { data } = await api.get<SMTPProps>('/smtp')

      if (data) {
        formik.setValues({
          smtp: data.smtp,
          email: data.email,
          password: '',
        })
      }
    }
    getSMTP()
  }, [formik])

  return (
    <Stack p={4}>
      <Stack
        pb={4}
        borderBottomWidth={'1px'}
        borderBottomStyle="solid"
        borderBottomColor={'gray.200'}
      >
        <Text fontWeight={'bold'} color={'gray.600'}>
          Configurações para envio de E-mail
        </Text>
        <Text color={'gray.600'}>
          Certifique de cadastrar os dados corretos, em caso de dúvida acesse
          este{' '}
          <Text as="a" href="#" color="brand.100">
            material
          </Text>
          .
        </Text>
      </Stack>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          direction={'row'}
          gridTemplateColumns="1fr 2fr"
          gap={8}
          alignItems={'center'}
          pt={4}
          pb={4}
          borderBottomWidth={'1px'}
          borderBottomStyle="solid"
          borderBottomColor={'gray.200'}
        >
          <Stack minWidth={'350px'}>
            <Text fontWeight={'bold'} color={'gray.600'}>
              Servidor SMTP
            </Text>
            <Text color={'gray.600'}>
              Dominio é o endereço que seus usuários acessam.
            </Text>
          </Stack>
          <Stack justifyContent={'center'} minWidth={'480px'}>
            <Input
              name="smtp"
              placeholder="SMTP ex.: smtp.servidor.com"
              value={formik.values.smtp}
              onChange={formik.handleChange}
              background={'white'}
              width="100%"
            />
          </Stack>
        </Stack>
        <Stack
          direction={'row'}
          gridTemplateColumns="1fr 2fr"
          gap={8}
          alignItems={'center'}
          pt={4}
          pb={4}
          borderBottomWidth={'1px'}
          borderBottomStyle="solid"
          borderBottomColor={'gray.200'}
        >
          <Stack minWidth={'350px'}>
            <Text fontWeight={'bold'} color={'gray.600'}>
              Usuário
            </Text>
          </Stack>
          <Stack justifyContent={'center'}>
            <Input
              type="text"
              name="email"
              placeholder="E-mail"
              background={'white'}
              width="100%"
              minWidth={'480px'}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </Stack>
        </Stack>
        <Stack
          direction={'row'}
          gridTemplateColumns="1fr 2fr"
          gap={8}
          alignItems={'center'}
          pt={4}
          pb={4}
          borderBottomWidth={'1px'}
          borderBottomStyle="solid"
          borderBottomColor={'gray.200'}
        >
          <Stack minWidth={'350px'}>
            <Text fontWeight={'bold'} color={'gray.600'}>
              Senha
            </Text>
          </Stack>
          <Stack justifyContent={'center'}>
            <Input
              type="password"
              name="password"
              placeholder="Senha"
              background={'white'}
              width="100%"
              minWidth={'480px'}
              value={formik.values.password}
              onChange={formik.handleChange}
            />
          </Stack>
        </Stack>
        <Stack alignItems={'flex-end'} pt={4}>
          <Button
            onClick={formik.submitForm}
            ml={4}
            bgColor={'brand.100'}
            color={'white'}
            _hover={{
              bg: 'brand.100',
              color: 'white',
              filter: 'brightness(.8)',
            }}
            isLoading={isLoading}
          >
            Salvar
          </Button>
        </Stack>
      </form>
    </Stack>
  )
}
