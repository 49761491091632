import React, { useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Button } from '@chakra-ui/react'

export function CheckoutForm() {
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setIsLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'http://localhost:3000/dashboard/checkout-success',
      },
    })

    /**
     * Ao redirecionar ele envia como parametro (payment_intent, payment_intent_client_secret, redirect_status)
     */

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message!)
    } else {
      setMessage('An unexpected error occurred.')
    }

    setIsLoading(false)
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <Button
        id="submit"
        type="submit"
        size="lg"
        w="full"
        mt={4}
        colorScheme="green"
        isLoading={isLoading}
      >
        <span id="button-text">Efetuar recarga</span>
      </Button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}
