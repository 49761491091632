import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import * as Popover from '@radix-ui/react-popover'
import { FiCalendar } from 'react-icons/fi'
import { useTheme } from 'styled-components'

import {
  Notification,
  NotificationContainer,
  PopoverArrow,
  PopoverContent,
} from './styles'
import { api } from '../../../../utils/api'

interface TasksProps {
  id: string
  date: string
  done: boolean
  history_id: string
  owner_id: string
  tenant_id: string
  created_at: string
  updated_at: string
}
export function Tasks() {
  const { colors } = useTheme()
  const [tasks, setTasks] = useState<TasksProps[]>([])

  useEffect(() => {
    ;(async () => {
      const { data } = await api.get('/tasks/day')
      setTasks(data)
    })()
  }, [])

  return (
    <Popover.Portal>
      <PopoverContent>
        <Popover.Close />
        <PopoverArrow />

        <header>
          <strong>Notificações</strong>
        </header>
        <NotificationContainer>
          {tasks.map((task) => (
            <Notification key={task.id}>
              <FiCalendar size={16} color={colors.brand[100]} />
              <div>
                <strong>Você tem um tarefa agendada para hoje!</strong>
                <span>
                  Sua tarefa agendada para o lead <strong>Amoratec</strong> está
                  agendada para hoje.
                </span>
              </div>
            </Notification>
          ))}
          <NavLink to="/dashboard/notificacoes">Ver todas tarefas</NavLink>
        </NotificationContainer>
      </PopoverContent>
    </Popover.Portal>
  )
}
