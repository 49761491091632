import { extendTheme } from '@chakra-ui/react'

const styles = {
  global: {
    p: {
      color: 'brand.300',
      fontSize: 'md',
    },
  },
}

const colors = {
  red: {
    500: '#C53030',
  },
  brand: {
    50: 'rgba(63, 110, 240, 0.1)',
    100: '#3F6EF0',
    200: '#3CDEEA',
    300: '#3F3F3F',
    400: '#F3F7F9',
    500: '#718096',
    600: '#676767',
    700: '#E2E8F0',
    900: '#394149',
  },
  gray: {
    50: '#F7FAFC',
    100: '#F3F7F9',
    200: '#E2E8F0',
    300: '#718096',
    500: '#676767',
    600: '#3F3F3F',
  },
} as const
/** Provider to Styled-components */
export const defaultTheme = { colors }

export const theme = extendTheme({ styles, colors })
