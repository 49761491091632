import {
  Stack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react'
import { Billing } from './Billing'
import { Emails } from './Emails'
import { Integrations } from './Integrations'
import { Profile } from './Profile'

export function Settings() {
  return (
    <>
      <Stack
        direction={'row'}
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Stack
          direction={'row'}
          gap={2}
          alignItems="center"
          justifyContent={'center'}
        >
          <Text fontWeight="extrabold">CONFIGURAÇÕES</Text>
        </Stack>
      </Stack>

      <Stack>
        <Tabs variant="unstyled">
          <TabList gap={4}>
            <Tab
              color={'gray.400'}
              bg={'gray.200'}
              borderRadius={'6px'}
              _selected={{
                color: 'white',
                bg: 'gray.600',
                borderRadius: '6px',
              }}
            >
              Perfil
            </Tab>
            <Tab
              color={'gray.400'}
              bg={'gray.200'}
              borderRadius={'6px'}
              _selected={{
                color: 'white',
                bg: 'gray.600',
                borderRadius: '6px',
              }}
            >
              Pagamentos
            </Tab>
            <Tab
              color={'gray.400'}
              bg={'gray.200'}
              borderRadius={'6px'}
              _selected={{
                color: 'white',
                bg: 'gray.600',
                borderRadius: '6px',
              }}
            >
              E-mails
            </Tab>
            <Tab
              color={'gray.400'}
              bg={'gray.200'}
              borderRadius={'6px'}
              _selected={{
                color: 'white',
                bg: 'gray.600',
                borderRadius: '6px',
              }}
            >
              Integrações
            </Tab>
          </TabList>
          <TabPanels pt={4}>
            <TabPanel
              background={'gray.50'}
              borderWidth={'1px'}
              borderStyle="solid"
              borderColor={'gray.200'}
              borderRadius="5px"
            >
              <Profile />
            </TabPanel>

            <TabPanel
            // background={'gray.50'}
            // borderWidth={'1px'}
            // borderStyle="solid"
            // borderColor={'gray.200'}
            // borderRadius="5px"
            >
              <Billing />
            </TabPanel>

            <TabPanel
              background={'gray.50'}
              borderWidth={'1px'}
              borderStyle="solid"
              borderColor={'gray.200'}
              borderRadius="5px"
            >
              <Emails />
            </TabPanel>

            <TabPanel
              background={'gray.50'}
              borderWidth={'1px'}
              borderStyle="solid"
              borderColor={'gray.200'}
              borderRadius="5px"
            >
              <Integrations />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </>
  )
}
