import React, { createContext, useState, useContext } from 'react'
import { api } from '../utils/api'

type UserProps = {
  id: string
  email: string
  first_name: string
  last_name: string
  tenant_id?: string
  domain?: string
  avatar?: string
  role: 'master' | 'admin' | 'user'
  phone?: string
  tenant: {
    id: string
    companies: {
      name: string
    }
  }
  created_at: string
  updated_at: string
}

interface AuthState {
  token: string
  user: UserProps
}

interface SignInCredentials {
  email: string
  password: string
}

interface SignInUserCredentials extends SignInCredentials {
  domain: string
}

interface AuthContextState {
  user: UserProps
  signIn(credentials: SignInCredentials): Promise<void>
  signOut(): void
  signInUser(credentials: SignInUserCredentials): Promise<void>
  updateUser(user: UserProps): void
  token?: string
}
interface AuthProviderProps {
  children?: React.ReactNode
}
const AuthContext = createContext<AuthContextState>({} as AuthContextState)

function AuthProvider({ children }: AuthProviderProps) {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@seeksales:token')
    const user = localStorage.getItem('@seeksales:user')

    if (token && user) {
      return { token, user: JSON.parse(user) }
    }

    return {} as AuthState
  })

  async function signIn({ email, password }: SignInCredentials) {
    const response = await api.post('/sessions', {
      email,
      password,
    })

    const { token, user } = response.data

    localStorage.setItem('@seeksales:token', token)
    localStorage.setItem('@seeksales:user', JSON.stringify(user))

    /** set token on header */
    api.defaults.headers.common.Authorization = `Bearer ${token}`

    setData({ token, user })
  }

  async function signInUser({
    email,
    password,
    domain,
  }: SignInUserCredentials) {
    const response = await api.post(`/sessions/${domain}`, {
      email,
      password,
    })

    const { token, user } = response.data

    localStorage.setItem('@seeksales:token', token)
    localStorage.setItem('@seeksales:user', JSON.stringify(user))

    /** set token on header */
    api.defaults.headers.common.Authorization = `Bearer ${token}`

    setData({ token, user })
  }

  function signOut() {
    localStorage.removeItem('@seeksales:token')
    localStorage.removeItem('@seeksales:user')

    setData({} as AuthState)
  }

  function updateUser(user: UserProps) {
    if (user.id === data.user.id) {
      localStorage.setItem('@seeksales:user', JSON.stringify(user))

      setData((prev) => ({
        ...prev,
        user,
      }))
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        token: data.token,
        signIn,
        signOut,
        signInUser,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth(): AuthContextState {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}

export { AuthProvider, useAuth }
