import { useState } from 'react'
import { useFormik } from 'formik'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react'
import { Input } from '../../../../components/Input'
import { api } from '../../../../utils/api'

interface CreateTaskProps {
  isOpen: boolean
  leadId: string
  onClose: () => void
  onCreateTask: (history: any) => void
}

export function CreateTask({ leadId, onCreateTask, ...rest }: CreateTaskProps) {
  const [isCreatingTask, setIsCreatingTaks] = useState(false)
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  })
  const formik = useFormik({
    initialValues: {
      title: '',
      date: '',
      content: '',
    },
    onSubmit: async (values) => {
      try {
        setIsCreatingTaks(true)
        const { data } = await api.post(`/tasks`, {
          lead_id: leadId,
          title: values.title,
          content: values.content,
          date: values.date,
          type: 'task',
        })

        const responseHistory = await api.get(
          `/leads/historic/${data.history_id}`,
        )
        onCreateTask(responseHistory.data)
        formik.resetForm()
        rest.onClose()

        toast({
          status: 'success',
          title: 'Tarefa criada com sucesso',
        })
      } catch {
        toast({
          status: 'error',
          title: 'Houve um erro ao criar a tarefa',
          description: 'Tente mais tarde',
        })
      } finally {
        setIsCreatingTaks(false)
      }
    },
  })

  return (
    <Modal {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader background={'brand.100'} color="white">
          Criar tarefa
        </ModalHeader>
        <ModalCloseButton color={'gray.200'} />
        <ModalBody>
          <form onSubmit={formik.handleSubmit}>
            <Stack gap={2}>
              <Input
                prompt="Título"
                name="title"
                placeholder="Ligar para o lead"
                value={formik.values.title}
                onChange={formik.handleChange}
                required
              />
              <Input
                prompt="Data"
                name="date"
                type="datetime-local"
                placeholder="10/12/2022"
                value={formik.values.date}
                onChange={formik.handleChange}
                required
              />
              <Input
                prompt="Descrição"
                name="content"
                placeholder="Lead solicitou para ligar amanhã"
                value={formik.values.content}
                onChange={formik.handleChange}
                required
              />
            </Stack>
          </form>
          <ModalFooter px={0}>
            <Button onClick={rest.onClose}>Fechar</Button>
            <Button
              onClick={formik.submitForm}
              ml={4}
              bgColor={'brand.100'}
              color={'white'}
              _hover={{
                bg: 'brand.100',
                color: 'white',
                filter: 'brightness(.8)',
              }}
              isLoading={isCreatingTask}
            >
              Criar tarefa
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
