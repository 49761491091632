import { useEffect, useState } from 'react'

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import Select from 'react-select'
import { api } from '../../../utils/api'
import { IUsers } from '../dtos/IUsers'

interface Props {
  isOpen?: boolean
  onClose: () => void
  onSubmit: (owner_id: string) => void
}

interface ReactSelectData {
  value: string
  label: string
}

export function AssignResponsibleLeads({
  onClose,
  isOpen = false,
  onSubmit,
}: Props) {
  const [users, setUsers] = useState<ReactSelectData[]>([])
  const [owner, setOwner] = useState<ReactSelectData | undefined>(undefined)

  function handleSubmit() {
    if (owner) onSubmit(owner?.value)
  }

  useEffect(() => {
    async function getUsers() {
      const { data } = await api.get<IUsers[]>(`/users`)

      const usersFormat = data.map((user: any) => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
      }))

      setUsers(usersFormat)
    }
    getUsers()
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent>
        <ModalHeader background={'brand.100'} color="white">
          Atribuir responsável pelo lead
        </ModalHeader>
        <ModalCloseButton color={'gray.200'} />

        <ModalBody pt={8} pb={8}>
          <form>
            <Select
              options={users}
              name="owner_id"
              placeholder="Responsavel pelo lead"
              onChange={(value) => setOwner(value!)}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Fechar</Button>
          <Button
            onClick={handleSubmit}
            ml={4}
            bgColor={'brand.100'}
            color={'white'}
            _hover={{
              bg: 'brand.100',
              color: 'white',
              filter: 'brightness(.8)',
            }}
          >
            Atribuir responsável
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
