import { InputHTMLAttributes } from 'react'
import { Container } from './styles'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  prompt: string
}
export function Input({ prompt, ...rest }: Props) {
  return (
    <Container>
      <label htmlFor={rest.id}>
        {prompt}
        {rest.required && <span>*</span>}
      </label>
      <input {...rest} />
    </Container>
  )
}
