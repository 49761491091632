import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Input } from '../../../../components/Input'
import Select from 'react-select'

import { api } from '../../../../utils/api'
import { Editor } from '../../../../components/EditorDraft'
import { IHistory } from '../../../../hooks/useLeads'
import { IContacts } from '../../../Dashboard'

interface Props {
  isOpen?: boolean
  onClose: () => void
  onSendMail: (contact: IHistory) => void
  leadId: string
}

interface ReactSelectData {
  value: string
  label: string
}

export function CreateNewEmail({
  onClose,
  isOpen = false,
  onSendMail,
  leadId,
}: Props) {
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  })

  const [body, setBody] = useState('')
  const [contacts, setContacts] = useState<ReactSelectData[]>([])
  const [selectedContact, setSelectedContact] = useState<
    ReactSelectData | undefined
  >({} as ReactSelectData)

  const [isLoadingContacts, setIsLoadingContacts] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      subject: '',
    },
    onSubmit: async (values) => {
      const data = {
        ...values,
        contact_id: selectedContact?.value,
        body,
      }

      try {
        setIsLoading(true)

        const schema = Yup.object({
          subject: Yup.string().required('Assunto é obrigatório'),
          body: Yup.string().required('Conteúdo é obrigatório'),
          contact_id: Yup.string().required(
            'Selecione o contato que deseja enviar o e-mail.',
          ),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        const response = await api.post(`/leads/${leadId}/mail`, data)

        onSendMail(response.data)

        formik.resetForm()
        setBody('')
        onClose()

        toast({
          title: 'Email enviado com sucesso!',
          status: 'success',
        })
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          return toast({
            title: 'Dados obrigatórios não preenchidos',
            description: 'Verifique os dados incompletos.',
            status: 'error',
          })
        }

        toast({
          title: 'Ops, houve um error inesperado',
          status: 'error',
        })
      } finally {
        setIsLoading(false)
      }
    },
  })

  useEffect(() => {
    async function loadContacts() {
      setIsLoadingContacts(true)
      const { data } = await api.get<IContacts[]>(`/contacts`, {
        params: {
          lead_id: leadId,
        },
      })

      const format = data.map((contact) => ({
        label: `${contact.first_name} ${contact.last_name} - ${contact.email}`,
        value: contact.id,
      }))

      setContacts(format)
      setIsLoadingContacts(false)
    }
    loadContacts()
  }, [leadId])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent>
        <ModalHeader background={'brand.100'} color="white">
          Enviar E-mail
        </ModalHeader>
        <ModalCloseButton color={'gray.200'} />

        <ModalBody pt={4}>
          <form onSubmit={formik.handleSubmit}>
            <Input
              prompt="Assunto"
              name="subject"
              placeholder="Assunto"
              onChange={formik.handleChange}
              value={formik.values.subject}
              required
            />
            <Stack mb={4} mt={4}>
              <Text fontSize={'12px'} color={'brand.500'}>
                Contato{' '}
                <Text as={'span'} color={'red.500'}>
                  *
                </Text>
              </Text>
              <Select
                placeholder="Selecione o contato"
                options={contacts}
                isLoading={isLoadingContacts}
                onChange={(value) => {
                  if (!value) return
                  setSelectedContact(value)
                }}
              />
            </Stack>

            <Text fontSize={'12px'} color={'brand.500'} mb={2}>
              Conteúdo{' '}
              <Text as={'span'} color={'red.500'}>
                *
              </Text>
            </Text>
            <Editor onChange={setBody} />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Fechar</Button>
          <Button
            onClick={formik.submitForm}
            ml={4}
            bgColor={'brand.100'}
            color={'white'}
            _hover={{
              bg: 'brand.100',
              color: 'white',
              filter: 'brightness(.8)',
            }}
            isLoading={isLoading}
          >
            Enviar Email
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
