import { ActionMeta, OnChangeValue } from 'react-select'
import CreatetableSelect from 'react-select/creatable'

interface ReactSelectData {
  value: string
  label: string
}

interface SelectOriginLeadProps {
  options: ReactSelectData[]
  isLoading?: boolean
  value?: ReactSelectData
  onCreateNewOrigin?: (newOrigin: string) => void
  onSelected?: (value: ReactSelectData) => void
  onClear?: () => void
}

export function SelectOriginLead({
  options,
  value = undefined,
  isLoading = false,
  onCreateNewOrigin,
  onSelected,
  onClear,
}: SelectOriginLeadProps) {
  function handleChange(
    newValue: OnChangeValue<ReactSelectData, false>,
    actionMeta: ActionMeta<ReactSelectData>,
  ) {
    if (
      actionMeta.action === 'create-option' &&
      newValue &&
      onCreateNewOrigin
    ) {
      onCreateNewOrigin(newValue.label)
    }

    if (actionMeta.action === 'select-option' && newValue && onSelected) {
      onSelected(newValue)
    }

    if (actionMeta.action === 'clear' && onClear) {
      onClear()
    }
  }

  return (
    <CreatetableSelect
      formatCreateLabel={(userInput) => `Criar origem "${userInput}"`}
      isClearable
      value={value}
      onChange={handleChange}
      options={options}
      isLoading={isLoading}
      placeholder="Selecione a origem"
    />
  )
}
