import { useState } from 'react'
import { useFormik } from 'formik'
import { useAuth } from '../../hooks/Auth'
import { useNavigate } from 'react-router-dom'
import {
  Input,
  Checkbox,
  Text,
  Button,
  Image,
  Link,
  useToast,
} from '@chakra-ui/react'
import logo from '../../assets/logo.svg'
import imageSignin from '../../assets/image-signin.png'
import styles from './styles.module.scss'

export function SignIn() {
  const { signIn } = useAuth()
  const navigate = useNavigate()
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  })
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    async onSubmit(values) {
      try {
        setIsLoading(true)

        await signIn(values)

        navigate('/dashboard/leads')
      } catch (error) {
        toast({
          title: 'Email ou senha não parece válida.',
          status: 'error',
        })
      } finally {
        setIsLoading(false)
      }
    },
  })

  return (
    <div className={styles.wrapper}>
      <div className={styles.containerForm}>
        <div>
          <Image src={logo} alt="" />
          <Text
            as="h1"
            fontSize="2xl"
            mb={8}
            color="gray.700"
            fontWeight="bold"
          >
            Faça seu login
          </Text>
          <form onSubmit={formik.handleSubmit}>
            <Input
              placeholder="E-mail"
              name="email"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <Input
              placeholder="Senha"
              name="password"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            <Checkbox mt={4} display="block" mb={4}>
              Lembrar-me
            </Checkbox>
            <Button
              isLoading={isLoading}
              type={'submit'}
              colorScheme="blue"
              w="100%"
            >
              Entrar
            </Button>
          </form>
          <Text className={styles['register-text']}>
            Não possui conta ainda?{' '}
            <Link href="#" color="blue.400">
              Crie sua conta agora.
            </Link>
          </Text>
        </div>
      </div>
      <div className={styles.background}>
        <h1>Bem vindo ao Seeksales</h1>
        <p>
          A plataforma de gestão de clientes para <br />
          acelerar o seu ciclo de vendas.
        </p>
        <Image src={imageSignin} alt="" />
      </div>
    </div>
  )
}
