import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const AnimationContainer = styled.div`
  height: inherit;
  width: inherit;
  position: absolute;
  z-index: -1;
`

export const Content = styled.div`
  max-width: 720px;
  color: #2d3748;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 2.5rem;
    font-weight: 900;
  }
  a {
    margin-top: 1rem;
  }
`
