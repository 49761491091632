import { ChangeEvent, useEffect, useRef, useState } from 'react'
import {
  Box,
  FormControl,
  FormLabel,
  Stack,
  Switch,
  Text,
  Textarea,
  CircularProgress,
} from '@chakra-ui/react'
import { Input } from '../../../../../components/Input'
import Select, { SingleValue } from 'react-select'

import { expirationDataList } from './expireDates'
import { FormikErrors } from 'formik'
import { api } from '../../../../../utils/api'
import { IProposalTemplate } from '../../../../ProposalsTemplate'
import { IContactsLeads } from '../../../../dtos/ILeads'

interface Props {
  values: any
  handleChange: (e: ChangeEvent<any>) => void
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<any>>
  isVisible?: boolean
  contacts?: IContactsLeads[]
}
const standardModelsOptions = [
  { label: 'Básico', value: 'basic' },
  { label: 'Padrão', value: 'standard' },
]

interface ReactSelectData {
  value: string
  label: string
  url_document?: string
}

export function StepOne({
  values,
  contacts,
  handleChange,
  setFieldValue,
  isVisible = false,
}: Props) {
  const previewRef = useRef<HTMLIFrameElement>(null)
  const [proposalTemplates, setProposalTemplates] = useState<ReactSelectData[]>(
    [],
  )
  const [templateUrl, setTemplateUrl] = useState<string>('')
  const [isLoadingProposalTemplates, setIsLoadingProposalTemplates] =
    useState(false)
  const [isLoadingTemplateUrl, setIsLoadingTemplateUrl] = useState(false)

  const contactsOptions =
    contacts && contacts.length > 0
      ? contacts.map((contact) => ({
          ...contact,
          value: contact.id,
          label: `${contact.first_name} ${contact.first_name} - ${contact.email}`,
        }))
      : undefined

  useEffect(() => {
    async function getProposalTemplates() {
      setIsLoadingProposalTemplates(true)
      const { data } = await api.get<IProposalTemplate[]>(`/proposals/template`)

      const formatData = data.map((template) => ({
        label: template.name,
        value: template.id,
        url_document: template.template_url,
      }))

      setProposalTemplates(formatData)
      setIsLoadingProposalTemplates(false)
    }
    getProposalTemplates()
  }, [])

  function handleSelectedTemplate(
    value: SingleValue<{
      label: string
      value: string
      url_document?: string
    }>,
  ) {
    setIsLoadingTemplateUrl(true)

    if (value?.url_document) {
      setTemplateUrl(value.url_document)
    }

    setFieldValue('template_id', value?.value)
  }

  return (
    <Box
      display={isVisible ? 'grid' : 'none'}
      gridTemplateColumns="1fr 1fr"
      gap={8}
      flexDirection={'row'}
      w="100%"
      mt={8}
    >
      <Box>
        <Stack mb={4}>
          <Text fontSize={'12px'} color={'brand.500'}>
            Modelo de proposta
            <Text as={'span'} color={'red.500'}>
              *
            </Text>
          </Text>
          <Select
            options={[...standardModelsOptions, ...proposalTemplates]}
            placeholder="Modelo de proposta"
            name="template"
            isLoading={isLoadingProposalTemplates}
            onChange={handleSelectedTemplate}
          />
        </Stack>
        <Input
          value={values.title}
          name="title"
          onChange={handleChange}
          prompt="Título da proposta"
          placeholder="ex. Reforma do quarto"
          required
        />

        <Stack mt={4}>
          <Text fontSize={'12px'} color={'brand.500'}>
            Data de expiracão
            <Text as={'span'} color={'red.500'}>
              *
            </Text>
          </Text>
          <Select
            options={expirationDataList}
            placeholder="Data de expiração"
            onChange={(value) => setFieldValue('expire_date', value?.value)}
          />
        </Stack>

        <Stack mt={4}>
          <Text fontSize={'12px'} color={'brand.500'}>
            Termo de compra
          </Text>
          <Textarea
            placeholder="Insira qualquer informação relevante sobre preços, condições de compra e
                        contratos."
            resize={'none'}
            onChange={(event) =>
              setFieldValue('purchase_term', event.target.value)
            }
          />
        </Stack>
        <Stack mt={4}>
          <FormControl display="flex" alignItems="center">
            <FormLabel
              htmlFor="email-alerts"
              mb="0"
              fontSize={'12px'}
              color={'brand.500'}
            >
              Enviar por e-mail
            </FormLabel>
            <Switch
              id="email-alerts"
              colorScheme="blue"
              value={values.sender_mail}
              onChange={(event) =>
                setFieldValue('sender_mail', !values.sender_mail)
              }
            />
          </FormControl>
        </Stack>
        {values.sender_mail && (
          <Stack mb={8} mt={4}>
            <Text fontSize={'12px'} color={'brand.500'}>
              Selecione o contato
              <Text as={'span'} color={'red.500'}>
                *
              </Text>
            </Text>
            <Select
              options={contactsOptions}
              placeholder="Selecione o contato"
              onChange={(value) => setFieldValue('contact', value)}
            />
          </Stack>
        )}
      </Box>
      <Box
        w={'100%'}
        height="440px"
        background={'gray.50'}
        borderWidth="1px"
        borderStyle={'solid'}
        borderColor="gray.200"
        display={'flex'}
        position={'relative'}
        // flexDirection="column"
        justifyContent={'center'}
        alignItems="center"
      >
        {!isLoadingTemplateUrl && !templateUrl && (
          <Box>
            <Text color="gray.400" fontSize="sm">
              Nenhum modelo selecionado.
            </Text>
          </Box>
        )}
        <Box
          h={'full'}
          width={'full'}
          position={'absolute'}
          top={0}
          left={0}
          zIndex={99}
          display={isLoadingTemplateUrl ? 'flex' : 'none'}
          alignItems="center"
          justifyContent="center"
          bg="rgba(255, 255, 255, 0.5)"
        >
          <CircularProgress isIndeterminate color="brand.100" />
        </Box>
        <Box
          h="full"
          w="full"
          display={!isLoadingTemplateUrl && !templateUrl ? 'none' : 'flex'}
        >
          <iframe
            ref={previewRef}
            title="display-document"
            src={
              templateUrl
                ? `https://view.officeapps.live.com/op/embed.aspx?src=${templateUrl}`
                : ''
            }
            onLoad={() => setIsLoadingTemplateUrl(false)}
            style={{
              zoom: 0.5,
              height: '100%',
              width: '100%',
            }}
          ></iframe>
        </Box>
      </Box>
    </Box>
  )
}
