import { ChangeEvent, useRef, useState } from 'react'
import { Avatar as ChakraAvatar, Stack } from '@chakra-ui/react'
import { FiCamera, FiCheck } from 'react-icons/fi'
import styles from './styles.module.scss'

interface AvatarProps {
  name: string
  src?: string
  size: 'xl' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | '2xl' | 'full' | undefined
  isLoading?: boolean
  onSubmit: (file: File) => Promise<void>
}

export function Avatar({
  name,
  src,
  size,
  isLoading = false,
  onSubmit,
}: AvatarProps) {
  const inputAvatarRef = useRef<HTMLInputElement>(null)
  const [preview, setPreview] = useState<string | null>(null)
  const [file, setFile] = useState<File | null>(null)

  function handleOpenInputAvatar() {
    inputAvatarRef.current?.click()
  }

  function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault()

    const file = event.target.files?.[0]

    if (!file) return setPreview(null)

    const previewUrl = URL.createObjectURL(file)

    setPreview(previewUrl)
    setFile(file)
  }

  async function handleSubmit() {
    if (file) {
      await onSubmit(file)
      setPreview(null)
    }
  }

  return (
    <Stack position={'relative'}>
      <ChakraAvatar name={name} src={preview || src} size={size} />

      <input
        ref={inputAvatarRef}
        type="file"
        name="avatar"
        id="avatar"
        style={{
          display: 'none',
        }}
        onChange={handleOnChange}
      />

      {preview || isLoading ? (
        <Stack
          as={'button'}
          position={'absolute'}
          bottom="2"
          right={'-1'}
          shadow="sm"
          height={8}
          width={8}
          borderRadius={'50%'}
          backgroundColor={'brand.200'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          color="gray.50"
          transition={'.2s ease'}
          _hover={{
            filter: 'brightness(0.8)',
            color: 'brand.100',
          }}
        >
          {isLoading ? (
            <div className={styles.loading} />
          ) : (
            <FiCheck size={16} onClick={handleSubmit} />
          )}
        </Stack>
      ) : (
        <Stack
          as={'button'}
          position={'absolute'}
          bottom="2"
          right={'-1'}
          shadow="sm"
          height={8}
          width={8}
          borderRadius={'50%'}
          backgroundColor={'gray.50'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          color="gray.300"
          transition={'.2s ease'}
          _hover={{
            filter: 'brightness(0.8)',
            color: 'brand.100',
          }}
        >
          <FiCamera size={16} onClick={handleOpenInputAvatar} />
        </Stack>
      )}
    </Stack>
  )
}
