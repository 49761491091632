import { Box, Text } from '@chakra-ui/react'

interface Props {
  page: number
}
export function ProgressStep({ page = 1 }: Props) {
  return (
    <Box display={'flex'} flexDirection="row" width={'100%'} gap={4}>
      <Box width={'33%'}>
        <Text mb={2} color={page === 1 ? 'gray.600' : 'brand.100'}>
          1. Detalhes do orçamento
        </Text>
        <Box
          width={'100%'}
          transition="ease-in .2s"
          height={'8px'}
          backgroundColor={'brand.100'}
        />
      </Box>
      <Box width={'33%'}>
        <Text mb={2} color={page <= 2 ? 'gray.600' : 'brand.100'}>
          2. Produtos
        </Text>
        <Box
          width={'100%'}
          transition="ease-in .2s"
          height={'8px'}
          backgroundColor={page >= 2 ? 'brand.100' : 'gray.50'}
        />
      </Box>
      <Box width={'33%'}>
        <Text mb={2} color={page <= 3 ? 'gray.600' : 'brand.100'}>
          3. Revisão
        </Text>
        <Box
          width={'100%'}
          transition="ease-in .2s"
          height={'8px'}
          backgroundColor={page >= 3 ? 'brand.100' : 'gray.50'}
        />
      </Box>
    </Box>
  )
}
