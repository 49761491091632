import { Box, Button, Heading, Stack, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { FiEye, FiEyeOff } from 'react-icons/fi'

interface NotificationProps {
  data: {
    title: string
    description: string
    done: boolean
    date: string
    createdAt: string
  }
  onClickTitle: () => void
  onMarkOff: () => Promise<void>
  onMark: () => Promise<void>
}

export function Notification({
  data,
  onClickTitle,
  onMark,
  onMarkOff,
}: NotificationProps) {
  const [isLoading, setIsLoading] = useState(false)

  async function handleOnMarkOff() {
    setIsLoading(true)
    await onMarkOff()
    setIsLoading(false)
  }

  async function handleOnMark() {
    setIsLoading(true)
    await onMark()
    setIsLoading(false)
  }

  return (
    <Stack
      p={5}
      borderWidth="1px"
      rounded="md"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      bg="white"
    >
      <Box opacity={data.done ? 0.6 : 1}>
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Heading
            fontSize="xl"
            color={data.done ? 'gray.800' : 'blue.500'}
            cursor="pointer"
            onClick={onClickTitle}
          >
            {data.title}
          </Heading>
          <Text fontSize="xs" m={0}>
            {data.date}
          </Text>
        </Stack>
        <Text fontSize="sm" color="gray.500">
          {data.description}
        </Text>
      </Box>
      {data.done ? (
        <Button
          size="xs"
          onClick={handleOnMarkOff}
          leftIcon={<FiEyeOff />}
          isLoading={isLoading}
        >
          Marcar como não lida
        </Button>
      ) : (
        <Button
          size="xs"
          onClick={handleOnMark}
          leftIcon={<FiEye />}
          isLoading={isLoading}
        >
          Marcar como lida
        </Button>
      )}
    </Stack>
  )
}
