import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Tag,
  Stack,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Badge,
  Select,
} from '@chakra-ui/react'
import { FiSearch, FiMoreHorizontal, FiPlus } from 'react-icons/fi'
import { useAuth } from '../../hooks/Auth'
import { api } from '../../utils/api'
import { CreateNewUser } from './CreateNewContact'
import { Link, useNavigate } from 'react-router-dom'

export interface IUser {
  id: string
  first_name: string
  last_name: string
  email: string
  role?: 'user' | 'admin'
  tenant_id?: string
  avatar?: string
  created_at: string
  updated_at: string
}

const statusLabels = {
  user: 'Usuário',
  admin: 'Administrador',
}

export function Users() {
  const { user } = useAuth()
  const navigate = useNavigate()
  const [users, setUsers] = useState<IUser[]>([])
  const [usersFilter, setUsersFilter] = useState<IUser[]>([])
  const [filterType, setfilterType] = useState<'user' | 'admin' | 'all'>('all')
  const [isOpenCreateNewContact, setIsOpenCreateNewContact] = useState(false)

  function handleNewContact(user: IUser) {
    if (user) {
      setUsers([...users, user])
    }
  }

  useEffect(() => {
    async function getUsers() {
      const { data } = await api.get<IUser[]>(`/users`)
      setUsers(data)
      setUsersFilter(data)
    }
    getUsers()
  }, [user, navigate])

  useEffect(() => {
    if (filterType === 'all') {
      return setUsersFilter(users)
    }

    const filterContacts = users.filter((user) => user.role === filterType)
    setUsersFilter(filterContacts)
  }, [users, filterType, user.id])

  function onChangeSelect(event: React.ChangeEvent<HTMLSelectElement>) {
    setfilterType(event.target.value as 'user' | 'admin' | 'all')
  }
  return (
    <>
      <CreateNewUser
        isOpen={isOpenCreateNewContact}
        onClose={() => setIsOpenCreateNewContact(false)}
        onRegisterUser={handleNewContact}
      />
      <Stack
        direction={'row'}
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Stack
          direction={'row'}
          gap={2}
          alignItems="center"
          justifyContent={'center'}
        >
          <Text fontWeight="extrabold">USUÁRIOS</Text>
          <Badge variant={'outline'}>{usersFilter.length}</Badge>
        </Stack>
        <Button
          colorScheme={'blue'}
          leftIcon={<FiPlus />}
          onClick={() => setIsOpenCreateNewContact(true)}
        >
          Novo usuário
        </Button>
      </Stack>
      <Stack
        direction={'row'}
        alignItems="center"
        justifyContent={'space-between'}
        bg="gray.50"
        borderWidth="1px"
        borderStyle={'solid'}
        borderColor="gray.200"
        borderRadius={'5px'}
        padding={3}
        mb={4}
      >
        <Stack direction={'row'} alignItems="center">
          <InputGroup>
            <InputLeftElement pointerEvents={'none'}>
              <FiSearch />
            </InputLeftElement>
            <Input placeholder="Buscar pelo nome" />
          </InputGroup>
          <Select placeholder="Tipo" onChange={onChangeSelect}>
            <option value="all">Todos</option>
            <option value="admin">Administrador</option>
            <option value="user">Colaborador</option>
          </Select>
        </Stack>
        <Stack
          direction={'row'}
          width="300px"
          alignItems="center"
          justifyContent={'flex-end'}
        >
          <Text fontSize={'12px'} color="gray.400">
            Resultado por página
          </Text>
          <Select width={'100px'}>
            <option value="5">5</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Select>
        </Stack>
      </Stack>
      {/* end Header filters */}
      {usersFilter.length > 0 ? (
        <Table
          variant="simple"
          borderWidth="1px"
          borderStyle={'solid'}
          borderColor="gray.200"
        >
          <Thead
            bg="gray.50"
            borderWidth="1px"
            borderStyle={'solid'}
            borderColor="gray.200"
          >
            <Tr>
              <Th>
                <Checkbox />
              </Th>
              <Th>Nome</Th>
              <Th>Tipo</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {usersFilter.map((user) => (
              <Tr
                key={user.id}
                bg={'white'}
                borderWidth="1px"
                borderStyle={'solid'}
                borderColor="gray.200"
              >
                <Td px="6" width="8">
                  <Checkbox />
                </Td>
                <Td display={'flex'} alignItems="center" gap={4}>
                  <Avatar
                    src={
                      user.avatar
                        ? `${process.env.REACT_APP_AWS_BUCKET_URL}/avatar/${user.avatar}`
                        : undefined
                    }
                    size={'md'}
                    name={`${user.first_name} ${user.last_name}`}
                  />
                  <span>
                    <Link to={`/dashboard/contacts/${user.id}`}>
                      <Text fontWeight="medium">
                        {`${user.first_name} ${user.last_name}`}
                      </Text>
                    </Link>
                    <Text fontSize={'14px'} color="gray.500">
                      {user.email}
                    </Text>
                  </span>
                </Td>
                <Td>
                  {user.role && (
                    <Tag size={'md'} variant="solid" colorScheme="green">
                      {statusLabels[user.role]}
                    </Tag>
                  )}
                </Td>

                <Td>
                  <button>
                    <FiMoreHorizontal size={24} />
                  </button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <p>Nenhum usuário encontrado</p>
      )}
    </>
  )
}
