import {
  Navigate,
  Route,
  Routes as RoutesRouterDOM,
  useLocation,
} from 'react-router-dom'
import { DashboardLayout } from '../layouts/DashboardLayout'
import { useAuth } from '../hooks/Auth'
import { Dashboard } from '../pages/Dashboard'
import { Products } from '../pages/Products'
import { Lead } from '../pages/Lead'
import { ProposalsTemplate } from '../pages/ProposalsTemplate'
import { SignIn } from '../pages/SignIn'
import { Users } from '../pages/Users'
import { Settings } from '../pages/Settings'
import { CheckoutCredits } from '../pages/Credits'
import { CheckoutSucess } from '../pages/CheckoutSuccess'
import { ListNotifications } from '../pages/ListNotifications'
import { Home } from '../pages/Home'

export function Routes() {
  const { user } = useAuth()

  return (
    <RoutesRouterDOM>
      <Route
        path="/"
        element={
          !user ? <SignIn /> : <Navigate to="/dashboard/leads" replace />
        }
      />

      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <DashboardLayout />
          </RequireAuth>
        }
      >
        <Route path="/dashboard" element={<Home />} />
        <Route path="/dashboard/leads" element={<Dashboard />} />
        <Route path="/dashboard/leads/:id" element={<Lead />} />
        <Route
          path="/dashboard/proposal-template"
          element={<ProposalsTemplate />}
        />
        <Route path="/dashboard/products" element={<Products />} />
        <Route path="/dashboard/users" element={<Users />} />
        <Route path="/dashboard/settings" element={<Settings />} />
        <Route path="/dashboard/notificacoes" element={<ListNotifications />} />
      </Route>
      <Route
        path="/dashboard/checkout-credits/:clientSecret"
        element={
          <RequireAuth>
            <CheckoutCredits />
          </RequireAuth>
        }
      />
      <Route
        path="/dashboard/checkout-success"
        element={
          <RequireAuth>
            <CheckoutSucess />
          </RequireAuth>
        }
      />
    </RoutesRouterDOM>
  )
}

// eslint-disable-next-line no-undef
function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth()
  const location = useLocation()

  if (!auth.user) {
    return <Navigate to="/" state={{ from: location }} replace />
  }

  return children
}
