import { useEffect, useState } from 'react'
import {
  Heading,
  HStack,
  VStack,
  Text,
  Input,
  Button,
  Grid,
  GridItem,
  Skeleton,
  Badge,
} from '@chakra-ui/react'
import { TaskItem, TasksList } from './components/TasksToDay'
import { api } from '../../utils/api'
import { format, parseISO } from 'date-fns'
import { NavLink } from 'react-router-dom'

interface ITasksTodayProps {
  id: string
  date: string
  done: boolean
  owner_id: string
  history_id: string
  tenant_id: string
  created_at: string
  updated_at: string
  history: {
    id: string
    title: string
    content: string
  }
  dateFormat?: string
  hour: string
  hourType: 'PM' | 'AM'
}
export function Home() {
  const [tasks, setTasks] = useState<ITasksTodayProps[]>([])
  const [isLoadingTasks, setIsLoadingTasks] = useState(false)

  useEffect(() => {
    ;(async () => {
      setIsLoadingTasks(true)
      const { data } = await api.get<ITasksTodayProps[]>('/tasks/day')

      const dataFormatDateString = data.map((task) => {
        const date = parseISO(task.date)
        return {
          ...task,
          dateFormat: format(date, "dd/MM/yyyy 'às' hh:mm"),
          hour: format(date, 'hh:mm'),
          hourType: format(date, 'bbb') as 'PM' | 'AM',
        }
      })

      setTasks(dataFormatDateString)
      setIsLoadingTasks(false)
    })()
  }, [])

  const tasksLimit = tasks.length > 0 ? tasks.slice(0, 2) : []

  return (
    <>
      <HStack
        alignItems="center"
        justifyContent="space-between"
        borderBottom={'1px'}
        borderBottomColor="gray.200"
        pb={8}
        mb={8}
      >
        <VStack alignItems={'start'}>
          <Heading size="md" color="gray.700">
            Bem vindo(a), Nicholas
          </Heading>
          <Text size="sm" color="gray.500">
            Este é o seu painel de recursos do sistema Seeksales.
          </Text>
        </VStack>
        <HStack>
          <Input
            bg="white"
            placeholder="Pesquisar lead por Nome ou e-mail"
            _placeholder={{ color: 'gray.300' }}
          />
          <Button colorScheme="blue" px={8}>
            Pesquisar
          </Button>
        </HStack>
      </HStack>
      <Grid templateColumns={'2fr 1fr'} gap={8}>
        <GridItem
          bg="gray.50"
          border={'1px'}
          borderColor="gray.200"
          rounded="sm"
          p={6}
        >
          <Heading size="sm" color="gray.700">
            Gráficos
          </Heading>
        </GridItem>
        <GridItem
          bg="gray.50"
          border={'1px'}
          borderColor="gray.200"
          rounded="sm"
          p={6}
        >
          <Heading
            size="sm"
            color="gray.700"
            mb={6}
            display="flex"
            alignItems={'center'}
            gap={2}
          >
            Tarefas do dia <Badge colorScheme="green">{tasks.length}</Badge>
          </Heading>
          <TasksList>
            {tasksLimit.map((task) => (
              <Skeleton key={task.id} mb={2} isLoaded={!isLoadingTasks}>
                <TaskItem
                  data={{
                    title: task.history.title,
                    description: task.history.content,
                    done: task.done,
                    hour: task.hour,
                    hourType: task.hourType,
                  }}
                  onClickMark={() => {}}
                />
              </Skeleton>
            ))}
          </TasksList>
          <HStack justifyContent="center" flex={1} mt={4}>
            <NavLink to="/dashboard/notificacoes">
              <Text color="blue.500" fontSize={'sm'}>
                Ver todas
              </Text>
            </NavLink>
          </HStack>
        </GridItem>
      </Grid>
    </>
  )
}
