import { ReactNode } from 'react'
import { HStack, VStack, Text, Button } from '@chakra-ui/react'
import { FiCheck } from 'react-icons/fi'

interface TasksListProsp {
  children: ReactNode
}
export function TasksList({ children }: TasksListProsp) {
  return <div>{children}</div>
}

interface TaskItemProps {
  data: {
    title: string
    description: string
    hour: string
    hourType: 'PM' | 'AM'
    done: boolean
  }
  onClickMark: () => void
}

export function TaskItem({ data, onClickMark }: TaskItemProps) {
  return (
    <HStack
      alignItems={'center'}
      gap={4}
      borderBottomWidth={'1px'}
      borderBottomColor="gray.200"
      py={4}
    >
      <VStack position="relative">
        <Text fontSize={'xs'} fontWeight="extrabold" color="gray.500">
          {data.hour}
        </Text>
        <Text fontSize={'xs'} color="gray.300" textTransform="uppercase">
          {data.hourType}
        </Text>
      </VStack>
      <HStack flex={1} justifyContent={'space-between'}>
        <VStack alignItems={'start'}>
          <Text fontWeight="extrabold" color="gray.500">
            {data.title}
          </Text>
          <Text fontSize={'xs'} color="gray.500">
            {data.description}
          </Text>
        </VStack>
        <Button
          variant="ghost"
          title={data.done ? 'Marcar como não feito' : 'Marcar como feito'}
          colorScheme={data.done ? 'green' : 'gray'}
          size="xs"
          rounded="full"
          onClick={onClickMark}
        >
          <FiCheck />
        </Button>
      </HStack>
    </HStack>
  )
}
