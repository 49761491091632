import { addDays, format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

const date = Date.now()

export const expirationDataList = [
  {
    label: `Em 15 dias (${format(addDays(date, 15), "d 'de' LLLL 'de' yyyy", {
      locale: ptBR,
    })})`,
    value: 15,
  },
  {
    label: `Em 30 dias (${format(addDays(date, 30), "d 'de' LLLL 'de' yyyy", {
      locale: ptBR,
    })})`,
    value: 30,
  },
  {
    label: `Em 60 dias (${format(addDays(date, 60), "d 'de' LLLL 'de' yyyy", {
      locale: ptBR,
    })})`,
    value: 60,
  },
  {
    label: `Em 90 dias (${format(addDays(date, 90), "d 'de' LLLL 'de' yyyy", {
      locale: ptBR,
    })})`,
    value: 90,
  },
]
