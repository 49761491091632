import { Stack, VStack } from '@chakra-ui/react'
import { CardIntegration } from './components/CardIntegration'
import siengeImg from '../../../assets/sienge.png'
import googleCalendarImage from '../../../assets/google-calendar.png'

export function Integrations() {
  return (
    <Stack>
      <VStack display={'grid'} gridTemplateColumns="1fr 1fr 1fr 1fr" gap={4}>
        <CardIntegration
          image={siengeImg}
          description="Integre seu orçamento e aumente sua eficiência com o Sienge."
          title="Sienge"
          onClick={() => {}}
        />
        <CardIntegration
          image={googleCalendarImage}
          description="Integre sua agenda do Google com nosso aplicativo e nunca mais esqueça uma tarefa."
          title="Google Calendar"
          onClick={() => {}}
          disabled
        />
      </VStack>
    </Stack>
  )
}
