import { useEffect, useState } from 'react'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { ICall } from '../pages/dtos/ICall'
import { ILeads } from '../pages/Dashboard'
import { api } from '../utils/api'
import { formatDate } from '../utils/formatDate'
import Swal from 'sweetalert2'
import { queryClient } from '.'

export interface IHistory {
  id: string
  title: string
  content: string
  type:
    | 'activity'
    | 'notes'
    | 'email'
    | 'task'
    | 'contact'
    | 'created'
    | 'updated'
    | 'changed-owner'
    | 'call'
  tenant_id: string
  owner?: {
    id: string
    first_name: string
    last_name: string
    avatar?: string
  }
  call_id?: string
  call?: ICall
  phone?: string
  company?: string
  owner_id: string
  lead_id: string
  created_at: string
  updated_at: string
}

export interface IProposals {
  id: string
  title: string
  purchase_term: string
  expire_date: string
  items_id: string
  amount: string
  template_id: string
  proposal_url: string
  owner?: {
    id: string
    first_name: string
    last_name: string
    avatar?: string
  }
  owner_id: string
  tenant_id: string
  lead_id: string
  created_at: string
  updated_at: string
  historics: IHistory[]
}

export function useLeads() {
  const navigate = useNavigate()
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  })
  const { id } = useParams()

  const { data: lead, isLoading } = useQuery(
    ['lead', id],
    async () => {
      const response = await api.get<ILeads>(`/leads/${id}`)
      return response.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    },
  )

  const dialogCreateTask = useDisclosure()
  const dialogCreateActivity = useDisclosure()
  const dialogCreateNewEmail = useDisclosure()
  const dialogCreateProposals = useDisclosure()
  const dialogEditLead = useDisclosure()
  // const [lead, setLead] = useState<ILeads>({} as ILeads)
  const [historics, setHistorics] = useState<IHistory[]>([])
  const [proposals, setProposals] = useState<IProposals[]>([])

  const historicsEmails =
    historics.length > 0
      ? historics.filter((history) => history.type === 'email')
      : ([] as IHistory[])

  function handleNewHistoric(historic: IHistory) {
    const date = formatDate(historic.created_at)

    const newHistoric = {
      ...historic,
      created_at: date,
    }

    setHistorics([newHistoric, ...historics])
  }

  function handleNewEmail(historic: IHistory) {
    const historyEmail: IHistory = {
      ...historic,
      created_at: formatDate(historic.created_at),
    }

    setHistorics([historyEmail, ...historics])
  }

  async function handleSubmitUpdateAvatar(avatar: File) {
    try {
      const formdata = new FormData()
      formdata.append('avatar', avatar)

      const { data } = await api.patch(`/leads/${id}/avatar`, formdata)

      // setLead({
      //   ...lead,
      //   avatar: data.avatar,
      // })
      queryClient.setQueryData(['lead', id], { ...lead, avatar: data.avatar })

      toast({
        status: 'success',
        title: 'Foto do lead atualizada com sucesso',
      })
    } catch {}
  }

  function handleOnUpdateLead(leadUpdate: ILeads) {
    // setLead((prevState) => ({ ...prevState, ...lead }))
    queryClient.setQueryData(['lead', id], { ...lead, ...leadUpdate })
  }

  async function handleDeleteLead() {
    const confirmed = await Swal.fire({
      title: 'Deseja realmente apagar este lead ?',
      icon: 'error',
      html: 'Confirme abaixo para prosseguir.',
      confirmButtonText: 'Deletar agora',
      cancelButtonText: 'Cancelar',
      focusConfirm: true,
      confirmButtonColor: '#E53E3E',
      iconColor: '#E53E3E',
      showCancelButton: true,
      cancelButtonColor: '#3F3F3F',
      cancelButtonAriaLabel: 'Cancelar',
      showCloseButton: true,
    })

    if (confirmed.isConfirmed) {
      await api.delete(`/leads/${id}`)

      toast({
        title: 'Lead excluído com sucesso',
        status: 'success',
      })

      navigate(-1)
    }
  }

  function onCreateNewProposal(proposal: IProposals) {
    const newProposal = {
      ...proposal,
      created_at: formatDate(proposal.created_at),
      expire_date: formatDate(proposal.expire_date),
      amount: new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(Number(proposal.amount)),
    }

    setProposals((prev) => [newProposal, ...prev])
  }

  useEffect(() => {
    async function getProposals() {
      const { data } = await api.get<IProposals[]>(`/proposals`, {
        params: {
          lead_id: id,
        },
      })

      const format = data.map((item) => {
        const createdAt = formatDate(item.created_at)
        const expireDate = formatDate(item.expire_date)

        return {
          ...item,
          amount: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(item.amount)),
          created_at: createdAt,
          expire_date: expireDate,
        }
      })
      setProposals(format)
    }
    getProposals()
  }, [id])

  return {
    dialogCreateTask,
    dialogCreateActivity,
    dialogCreateNewEmail,
    dialogCreateProposals,
    dialogEditLead,
    onCreateNewProposal,
    handleDeleteLead,
    handleOnUpdateLead,
    handleSubmitUpdateAvatar,
    handleNewHistoric,
    handleNewEmail,
    lead,
    historics,
    proposals,
    historicsEmails,
    isLoading,
  }
}
