import { Box, Button, Image, Stack, Text } from '@chakra-ui/react'

interface CardIntegrationProps {
  image: string
  description: string
  title: string
  onClick: () => void
  disabled?: boolean
}
export function CardIntegration({
  title,
  description,
  image,
  onClick,
  disabled,
}: CardIntegrationProps) {
  return (
    <Box
      w="100%"
      p={8}
      display="flex"
      flexDirection={'column'}
      justifyContent="center"
      alignItems={'start'}
      borderWidth="1px"
      borderStyle={'solid'}
      borderColor="gray.200"
      bg={'gray.100'}
      borderRadius={'10px'}
      m={0}
      opacity={disabled ? 0.6 : undefined}
    >
      <Image width={'100px'} src={image} alt={title} />
      <Text color="gray.400" mt={4} mb={4}>
        {description}
      </Text>
      <Stack
        w={'100%'}
        pt={4}
        borderTopWidth="1px"
        borderTopStyle={'solid'}
        borderTopColor="gray.200"
      >
        <Button
          bg={'gray.200'}
          _hover={{
            filter: 'brightness(.8)',
          }}
          disabled={disabled}
          onClick={onClick}
        >
          Conectar
        </Button>
      </Stack>
    </Box>
  )
}
