import { Box, Text, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react'
import { IContacts } from '..'
import { ILeads } from '../../../../Dashboard'
import { ISelectedProduct as Products } from '../StepTwo'

interface StepData {
  title: string
  expire_date: string
  template_id: string
  contact: IContacts | null
  purchase_term: string
  items: null | Products[]
  lead: ILeads
}

interface Props {
  isVisible?: boolean
  data: StepData
}
export function StepThree({ data, isVisible = false }: Props) {
  return (
    <Box
      display={isVisible ? 'block' : 'none'}
      w="100%"
      mt={8}
      overflowY="auto"
    >
      <Text fontWeight="bold" fontSize={'sm'} mb={2}>
        Detalhes
      </Text>
      <Box display="grid" gridTemplateColumns={'1fr 1fr'} mb={8}>
        <Box
          borderWidth={1}
          borderColor="gray.200"
          display="flex"
          gap={2}
          px={4}
          py={2}
        >
          <Text fontWeight="bold">Título: </Text>
          <Text>{data.title}</Text>
        </Box>
        <Box
          borderWidth={1}
          borderColor="gray.200"
          display="flex"
          gap={2}
          px={4}
          py={2}
        >
          <Text fontWeight="bold">Data de expiração:</Text>
          <Text>em {data.expire_date} dias</Text>
        </Box>
        <Box borderWidth={1} borderColor="gray.200" px={4} py={2}>
          <Text fontWeight="bold">Termo de compra: </Text>
          <Text>{data.purchase_term}</Text>
        </Box>
        <Box borderWidth={1} borderColor="gray.200" px={4} py={2}>
          <Text fontWeight="bold">Modelo: </Text>
          <Text>{data.template_id}</Text>
        </Box>
        <Box borderWidth={1} borderColor="gray.200" px={4} py={2}>
          <Text fontWeight="bold">Enviar para: </Text>
          <Text>
            {data.contact &&
              `${data.contact.first_name} ${data.contact.last_name} - ${data.contact.email}`}
          </Text>
        </Box>
      </Box>

      <Text fontWeight="bold" fontSize={'sm'} mb={2}>
        Produtos
      </Text>

      <Table>
        <Thead background={'gray.50'} overflowY={'auto'}>
          <Tr>
            <Th>Descrição</Th>
            <Th>Unidade de medida</Th>
            <Th>Valor unitário</Th>
            <Th>Quantidade</Th>
            <Th>Total</Th>
          </Tr>
        </Thead>
        <Tbody background={'white'}>
          {data.items &&
            data.items.map((product) => (
              <Tr key={product.id}>
                <Td>{product.description}</Td>
                <Td>{product.unit_of_measure}</Td>
                <Td>{product.amount_format}</Td>
                <Td>{product.quantity}</Td>
                <Td>{product.total}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </Box>
  )
}
