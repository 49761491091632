import { BrowserRouter } from 'react-router-dom'
import { Routes } from './routes'
import { AppProviders } from './hooks'

function App() {
  return (
    <AppProviders>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </AppProviders>
  )
}

export default App
