import { useState } from 'react'
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { api } from '../../../../utils/api'
import { IProposals } from '../../../../hooks/useLeads'
import { StepOne } from './StepOne'
import { ISelectedProduct, StepTwo } from './StepTwo'
import { StepThree } from './StepThree'
import { ProgressStep } from './ProgressStep'
import { useFormik } from 'formik'
import { ILeads } from '../../../Dashboard'

interface Props {
  isOpen?: boolean
  onClose: () => void
  onNewCreateProposal: (propostal: IProposals) => void
  lead: ILeads
}

export type IContacts = {
  id: string
  first_name: string
  last_name: string
  email: string
  value: string
  label: string
}
interface ItemsPropsRequest {
  id: string
  description: string
  unit_of_measure: string
  quantity: number
  amount: number
}

export function CreateProposals({
  onClose,
  isOpen = false,
  onNewCreateProposal,
  lead,
}: Props) {
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)

  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  })

  const formik = useFormik({
    initialValues: {
      title: '',
      expire_date: '',
      template_id: '',
      purchase_term: '',
      items: [],
      sender_mail: false,
      contact: null as IContacts | null,
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        let items: ItemsPropsRequest[] = []

        if (values.items.length > 0) {
          items = values.items.map((item: ISelectedProduct) => ({
            id: item.id,
            description: item.description,
            unit_of_measure: item.unit_of_measure,
            quantity: item.quantity,
            amount: Number(item.amount),
          }))
        }
        const data = {
          ...values,
          items,
          contact_id:
            values.contact && values.sender_mail ? values.contact.id : null,
        }

        const response = await api.post(`/proposals/${lead.id}`, data)

        toast({
          title: 'Proposta realizada com sucesso',
          status: 'success',
        })

        window.open(response.data.proposal_url)
        formik.resetForm()
        onNewCreateProposal(response.data)
        onClose()
        setPage(1)
      } catch (error) {
        toast({
          title: 'Ops, houve um erro ao emitir a proposta.',
          status: 'error',
        })
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    },
  })

  function validateStepOne() {
    if (!formik.values.expire_date) return false
    if (!formik.values.template_id) return false
    if (!formik.values.title) return false
    return true
  }

  function validateStepTwo() {
    if (!formik.values.items) return false
    return true
  }

  /** Paginate */
  function nextPage() {
    /** Validate step 1 */
    if (page + 1 === 2) {
      if (!validateStepOne()) return
    }
    /** Validate step 2 */
    if (page + 1 === 3) {
      if (!validateStepTwo()) return
    }
    if (page + 1 === 4) {
      formik.submitForm()
      return
    }

    setPage((prev) => prev + 1)
    // formik.submitForm();
  }

  function previousPage() {
    if (page - 1 < 1) return
    setPage((prev) => prev - 1)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent>
        <ModalHeader display={'flex'} justifyContent="center">
          <Text fontWeight={'bold'} fontSize={'2xl'}>
            Criar novo orçamento
          </Text>
        </ModalHeader>
        <ModalCloseButton color={'gray.200'} />
        <ModalBody pt={4}>
          <Box margin={'0 auto'} maxWidth={'1271px'} pb="104px">
            <ProgressStep page={page} />

            <StepOne
              values={formik.values}
              handleChange={formik.handleChange}
              setFieldValue={formik.setFieldValue}
              isVisible={page === 1}
              contacts={lead.contacts}
            />

            <StepTwo
              isVisible={page === 2}
              onChangeValue={(values) => formik.setFieldValue('items', values)}
            />

            <StepThree
              data={{
                ...formik.values,
                lead,
              }}
              isVisible={page === 3}
            />
          </Box>
        </ModalBody>
        <ModalFooter
          display={'flex'}
          alignItems="center"
          justifyContent={'space-between'}
          paddingY={8}
          paddingX={16}
          height="104px"
          background={'gray.50'}
          position="sticky"
          bottom={0}
          w="full"
          boxShadow="4px -4px 12px rgba(0, 0, 0, 0.1)"
        >
          <Button onClick={onClose}>Sair</Button>

          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems="center"
            gap={4}
          >
            {page > 1 && (
              <Button onClick={previousPage} disabled={isLoading}>
                Voltar
              </Button>
            )}
            <Button
              onClick={nextPage}
              ml={4}
              bgColor={'brand.100'}
              color={'white'}
              _hover={{
                bg: 'brand.100',
                color: 'white',
                filter: 'brightness(.8)',
              }}
              isLoading={isLoading}
            >
              {page < 3 ? 'Próximo' : 'Enviar'}
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
