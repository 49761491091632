import { ReactNode } from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { AuthProvider } from './Auth'
import { defaultTheme, theme } from '../styles/theme'
import { ThemeProvider } from 'styled-components'
import { QueryClient, QueryClientProvider } from 'react-query'

export const queryClient = new QueryClient()

interface Props {
  children?: ReactNode
}

export function AppProviders({ children }: Props) {
  return (
    <ChakraProvider theme={theme}>
      <ThemeProvider theme={defaultTheme}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>{children}</AuthProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </ChakraProvider>
  )
}
