export const gaugeLengthOptions = [
  {
    label: 'km - Quilômetro',
    value: 'km',
  },
  {
    label: 'hm - Hectômetro',
    value: 'hm',
  },
  {
    label: 'dam - Decâmetro',
    value: 'dam',
  },
  {
    label: 'm - Metro',
    value: 'm',
  },
  {
    label: 'dm - Decímetro',
    value: 'dm',
  },
  {
    label: 'cm - Centímetro',
    value: 'cm',
  },
  {
    label: 'mm - Milímetro',
    value: 'mm',
  },
]

export const massUnitOptions = [
  {
    label: 'kg - Quilograma',
    value: 'kg',
  },
  {
    label: 'hg - Hectograma',
    value: 'hg',
  },
  {
    label: 'dag - Decagrama',
    value: 'dag',
  },
  {
    label: 'g - Grama',
    value: 'g',
  },

  {
    label: 'dg - Decigrama',
    value: 'dg',
  },
  {
    label: 'cg - Centigrama',
    value: 'cg',
  },
  {
    label: 'mg - Miligrama',
    value: 'mg',
  },
]

export const capacityUnitOptions = [
  {
    label: 'kl - Quilolitro',
    value: 'kl',
  },
  {
    label: 'hl - Hectolitro',
    value: 'hl',
  },
  {
    label: 'dal - Decalitro',
    value: 'dal',
  },
  {
    label: 'l - Litro',
    value: 'l',
  },

  {
    label: 'dl - Decilitro',
    value: 'dl',
  },
  {
    label: 'cl - Centilitro',
    value: 'cl',
  },
  {
    label: 'ml - Mililitro',
    value: 'ml',
  },
]

export const areaMeasurementUnitOptions = [
  {
    label: 'km² - Quilometro quadrado',
    value: 'km²',
  },
  {
    label: 'hm² - Hectômetro quadrado',
    value: 'hm²',
  },
  {
    label: 'dam² - Decâmetro quadrado',
    value: 'dam²',
  },
  {
    label: 'm² - Metro quadrado',
    value: 'm²',
  },

  {
    label: 'dm² - Decímetro quadrado',
    value: 'dm²',
  },

  {
    label: 'cm² - Centímetro quadrado',
    value: 'cm²',
  },
  {
    label: 'mm² - Milímetro quadrado',
    value: 'mm²',
  },
]

export const volumeUnitOptions = [
  {
    label: 'km³ - Quilômetro cúbico',
    value: 'km³',
  },
  {
    label: 'hm³ - Hectômetro cúbico',
    value: 'hm³',
  },

  {
    label: 'dam³ - Decâmetro cúbico',
    value: 'dam³',
  },
  {
    label: 'm³ - Metro cúbico',
    value: 'm³',
  },
  {
    label: 'dm³ - Decímetro cúbico',
    value: 'dm³',
  },
  {
    label: 'cm³ - Centímetro cúbico',
    value: 'cm³',
  },
  {
    label: 'mm³ - Milímetro cúbico',
    value: 'mm³',
  },
]

export const timeMeasurementUnitOptions = [
  {
    label: 'Mês',
    value: 'mes',
  },
  {
    label: 'Dia',
    value: 'd',
  },
  {
    label: 'Hora',
    value: 'h',
  },

  {
    label: 'Minuto',
    value: 'min',
  },
  {
    label: 'Segundo',
    value: 's',
  },
  {
    label: 'Microssegundo',
    value: 'us',
  },
]

export const unitsOptions = [
  {
    label: 'Unidade',
    value: 'un',
  },
  {
    label: 'Verba',
    value: 'vb',
  },
]

export interface ReactSelectDataOptions {
  label: string
  value: string
}

export interface GroupedOption {
  readonly label: string
  readonly options: readonly ReactSelectDataOptions[]
}

export const groupedOptions: readonly GroupedOption[] = [
  {
    label: 'Medida de comprimento',
    options: gaugeLengthOptions,
  },
  {
    label: 'Unidades de massa',
    options: massUnitOptions,
  },
  {
    label: 'Unidades de capacidade',
    options: capacityUnitOptions,
  },
  {
    label: 'Unidades de medida de área',
    options: areaMeasurementUnitOptions,
  },
  {
    label: 'Unidades de volume',
    options: volumeUnitOptions,
  },
  {
    label: 'Unidades de medida de tempo',
    options: timeMeasurementUnitOptions,
  },
  {
    label: 'Unidades',
    options: unitsOptions,
  },
]
