import { useState, useRef } from 'react'
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react'
import { FiPause, FiPlay } from 'react-icons/fi'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { ICall } from '../../pages/dtos/ICall'
import { convertDurationToTimeString } from '../../utils/convertDurationToTimeString'

interface Props {
  data: ICall
}

export function ItemTimeline({ data }: Props) {
  const audioRef = useRef<HTMLAudioElement>(null)

  const [isPlaying, setIsPlaying] = useState(false)
  const [progress, setProgress] = useState(0)

  function setupProgressListener() {
    if (!audioRef.current) return

    audioRef.current.currentTime = 0

    audioRef.current.addEventListener('timeupdate', (event) => {
      if (audioRef.current)
        setProgress(Math.floor(audioRef.current.currentTime))
    })
  }

  function tooglePlay() {
    if (!audioRef.current) return
    if (isPlaying) {
      audioRef.current.pause()
      setIsPlaying(!isPlaying)
    } else {
      audioRef.current.play()
      setIsPlaying(!isPlaying)
    }
  }

  function handleSeek(amount: number | number[]) {
    if (audioRef.current && !Array.isArray(amount)) {
      audioRef.current.currentTime = amount
      setProgress(amount)
    }
  }
  return (
    <VStack
      py={6}
      px={4}
      bg={'gray.50'}
      border={'1px solid #E2E8F0'}
      borderRadius={'5px'}
      mt={6}
    >
      <HStack justifyContent="space-between" w="full">
        <div>
          <Text fontWeight="bold">Áudio da chamada</Text>
          <Text fontSize={'small'} display="flex" alignItems={'center'} gap={2}>
            {data.audio_url ? 'Disponível' : 'Indisponivel'}
            <Box
              display={'block'}
              as="span"
              h="8px"
              w="8px"
              rounded="full"
              bg={data.audio_url ? 'green.500' : 'gray.100'}
            />
            {data.audio_url ? 'Atendido' : '- -'}
          </Text>
        </div>
        <HStack alignItems="center" gap={4}>
          <Button size="sm">Download da chamada</Button>
          <Button onClick={tooglePlay} size="sm">
            {isPlaying ? <FiPause size={16} /> : <FiPlay size={16} />}
          </Button>
        </HStack>
      </HStack>
      {data.audio_url && (
        <audio
          ref={audioRef}
          src={data.audio_url}
          onLoadedMetadata={setupProgressListener}
        />
      )}
      <HStack alignItems={'center'} gap={3} w="full" pt={8}>
        <Text fontSize="small">{convertDurationToTimeString(progress)}</Text>
        <VStack h={'4px'} flex={1} bg="gray.100">
          <Slider
            max={data.duration}
            value={progress}
            onChange={handleSeek}
            trackStyle={{ backgroundColor: '#3F6EF0' }}
            railStyle={{ background: '#E0E0E0' }}
            handleStyle={{ borderColor: '#3F6EF0' }}
          />
        </VStack>
        <Text fontSize="small">
          {convertDurationToTimeString(data.duration)}
        </Text>
      </HStack>
      <Box w="full">
        <Text fontSize={'small'} color="gray.500">
          <strong>Custo:</strong>{' '}
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(data.call_cost)}
        </Text>
      </Box>
    </VStack>
  )
}

// type StatusCallProps = {
//     status: "enabled" | "available"
// };

// function StatusCall({ status }: StatusCallProps) {
//     return (
//         <Text fontSize={"small"} display="flex" alignItems={"center"} gap={2}>
//             <Box
//                 display={"block"}
//                 as='span'
//                 h="8px"
//                 w="8px"
//                 rounded="full"
//                 bg={status === "available" ? "green.500" : "orange.500"}
//             />
//             {status === "available" ? "Disponivel" : "Indisponivel no momento"}
//         </Text>
//    );
// }
