import { useEffect, useState } from 'react'
import { Button, Stack, Text } from '@chakra-ui/react'
import { Card } from '../../../../../components/Card'
import { Contact } from '../../../../../components/Contact'
import { IContacts, ILeads } from '../../../../Dashboard'
import { FiPlus } from 'react-icons/fi'

import styles from './styles.module.scss'
import { CreateNewContact } from '../../CreateNewContact/CreateNewContact'

const labelsStatus = {
  new: 'Novo',
  'in-progress': 'Em andamento',
  'open-business': 'Negocio aberto',
  unqualified: 'Não qualificado',
  'bad-time': 'Momento ruim',
}

interface Props {
  lead: Omit<ILeads, 'status'> & {
    status: keyof typeof labelsStatus
  }
}

export function Details({ lead }: Props) {
  const [contacts, setContacts] = useState<IContacts[]>([])
  const [createNewContactIsOpen, setCreateNewContactIsOpen] = useState(false)

  function handleRegisterNewContact(contact: IContacts) {
    setContacts([...contacts, contact])
  }

  useEffect(() => {
    if (lead.contacts && lead.contacts.length > 0) setContacts(lead.contacts)
  }, [lead.contacts])

  return (
    <>
      <CreateNewContact
        onClose={() => setCreateNewContactIsOpen(false)}
        onRegisterContact={handleRegisterNewContact}
        isOpen={createNewContactIsOpen}
        leadId={lead?.id!}
      />
      <div className={styles.gridDetails}>
        <Card>
          <Text fontSize={'md'} fontWeight="bold" mb={4}>
            Detalhes da empresa
          </Text>
          <Stack display={'grid'} gridTemplateColumns="1fr 1fr" gap={4}>
            <Stack>
              <Text color={'brand.500'} fontSize="small">
                Setor
              </Text>
              <Text>{lead?.sector ? lead?.sector : '--'}</Text>
            </Stack>
            <Stack>
              <Text color={'brand.500'} fontSize="small">
                Número de funcionários
              </Text>
              <Text>
                {lead?.number_employees ? lead?.number_employees : '--'}
              </Text>
            </Stack>
            <Stack>
              <Text color={'brand.500'} fontSize="small">
                Cidade
              </Text>
              <Text>{lead.address ? lead.address.state : '--'}</Text>
            </Stack>
            <Stack>
              <Text color={'brand.500'} fontSize="small">
                Município
              </Text>
              <Text>{lead.address ? lead.address.county : '--'}</Text>
            </Stack>
            <Stack>
              <Text color={'brand.500'} fontSize="small">
                Código postal
              </Text>
              <Text>{lead.address ? lead.address.zip_code : '--'}</Text>
            </Stack>
            <Stack>
              <Text color={'brand.500'} fontSize="small">
                Tipo
              </Text>
              <Text>{lead?.type === 'company' ? 'Empresa' : 'Projeto'}</Text>
            </Stack>
          </Stack>
          <Stack pt={4}>
            <Text color={'brand.500'} fontSize="small">
              Descrição
            </Text>
            <Text>{lead.description}</Text>
          </Stack>
        </Card>
        <Card>
          <Stack
            direction={'row'}
            alignItems="center"
            justifyContent={'space-between'}
            mb={8}
          >
            <Text fontSize={'md'} fontWeight="bold">
              Contatos
            </Text>
            <Button
              leftIcon={<FiPlus />}
              fontSize={'sm'}
              padding={0}
              bg={'transparent'}
              fontWeight="bold"
              color={'brand.100'}
              onClick={() => setCreateNewContactIsOpen(true)}
              _hover={{
                filter: 'brightness(0.8)',
              }}
              _active={{
                filter: 'brightness(0.7)',
              }}
            >
              novo contato
            </Button>
          </Stack>
          {contacts.length > 0 ? (
            contacts.map((contact) => (
              <Contact contact={contact} key={contact.id} />
            ))
          ) : (
            <Stack
              h="88px"
              w="100%"
              justifyContent="center"
              alignItems="center"
              bg={'gray.100'}
              borderRadius="5px"
            >
              <Text fontSize={'sm'} color="gray.500">
                Este lead ainda não possui contato.
              </Text>
            </Stack>
          )}
        </Card>
        <Card>
          <Text fontSize={'md'} fontWeight="bold" mb={4}>
            Sobre o lead
          </Text>

          <Stack display={'grid'} gridTemplateColumns="1fr 1fr" gap={4}>
            <Stack>
              <Text color={'brand.500'} fontSize="small">
                Responsável pelo lead
              </Text>
              <Text>
                {lead.owner
                  ? `${lead.owner.first_name} ${lead.owner.last_name}`
                  : 'Não atribuído'}
              </Text>
            </Stack>
            <Stack>
              <Text color={'brand.500'} fontSize="small">
                Status
              </Text>
              <Text>{labelsStatus[lead.status]}</Text>
            </Stack>
            <Stack>
              <Text color={'brand.500'} fontSize="small">
                Origem
              </Text>
              <Text>{lead.origin ? lead.origin.name : 'Não informado'}</Text>
            </Stack>
          </Stack>
        </Card>
      </div>
    </>
  )
}
