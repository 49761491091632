import { Avatar, Button, Input, Stack, Text, useToast } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { ChangeEvent, useRef, useState } from 'react'
import { useAuth } from '../../../hooks/Auth'
import { api } from '../../../utils/api'
import style from './styles.module.scss'

export function Profile() {
  const inputAvatarRef = useRef<HTMLInputElement>(null)
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  })
  const { user, updateUser } = useAuth()
  const [previewAvatar, setPreviewAvatar] = useState<string | null>(null)
  const [avatar, setAvatar] = useState<File | null>(null)
  const [updateAvatarIsLoading, setUpdateAvatarIsLoading] = useState(false)

  const initialValues = {
    ...user,
    password: '',
  }

  const formik = useFormik({
    initialValues,
    async onSubmit(values) {
      if (user.tenant_id) {
        const { data } = await api.put(`/users/${values.id}`, values)
        updateUser(data)
      } else {
        const { data } = await api.put(`/tenant/${values.id}`, values)
        updateUser(data)
      }
    },
  })

  function handleOnChangeInputAvatar(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault()
    const file = e.target.files?.[0]

    if (!file) {
      return setPreviewAvatar(null)
    }
    const previewUrl = URL.createObjectURL(file)
    setPreviewAvatar(previewUrl)
    setAvatar(file)
  }

  function handleOpenInputAvatar() {
    inputAvatarRef.current?.click()
  }

  async function handleUpdateAvatar() {
    if (avatar) {
      try {
        setUpdateAvatarIsLoading(true)
        const formdata = new FormData()
        formdata.append('avatar', avatar)

        const { data } = await api.patch(`/users/${user.id}/avatar`, formdata)

        updateUser(data)
        setPreviewAvatar(null)
        toast({
          status: 'success',
          title: 'Foto de perfil atualizada com sucesso',
        })
      } catch (error: any) {
        toast({
          title: error.response.data.message,
          status: 'error',
        })
      } finally {
        setUpdateAvatarIsLoading(false)
      }
    }
  }

  return (
    <Stack padding={4}>
      <Stack
        pb={4}
        borderBottomWidth={'1px'}
        borderBottomStyle="solid"
        borderBottomColor={'gray.200'}
      >
        <Text fontWeight={'bold'} color={'gray.600'}>
          Meu perfil
        </Text>
        <Text color={'gray.600'}>Atualize sua foto e dados pessoais.</Text>
      </Stack>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          direction={'row'}
          gridTemplateColumns="1fr 2fr"
          gap={8}
          alignItems={'center'}
          pt={4}
          pb={4}
          borderBottomWidth={'1px'}
          borderBottomStyle="solid"
          borderBottomColor={'gray.200'}
        >
          <Stack minWidth={'350px'}>
            <Text fontWeight={'bold'} color={'gray.600'}>
              Foto de perfil
            </Text>
          </Stack>
          <Stack alignItems={'center'} direction="row" gap={4}>
            <input
              type="file"
              accept="image/*"
              name="avatar"
              style={{ display: 'none' }}
              ref={inputAvatarRef}
              onChange={handleOnChangeInputAvatar}
            />
            <Avatar
              name={`${user.first_name} ${user.last_name}`}
              src={
                previewAvatar ||
                `${process.env.REACT_APP_AWS_BUCKET_URL}/avatar/${user?.avatar}`
              }
              size={'lg'}
              cursor="pointer"
              onClick={handleOpenInputAvatar}
              transition={'0.2s ease'}
              _hover={{
                filter: 'brightness(0.8)',
              }}
            />
            {updateAvatarIsLoading ? (
              <p>Carregando...</p>
            ) : (
              previewAvatar && (
                <>
                  <button
                    type="button"
                    className={style.buttonAvatarCancel}
                    onClick={() => setPreviewAvatar(null)}
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    className={style.buttonAvatarSave}
                    onClick={handleUpdateAvatar}
                  >
                    Salvar
                  </button>
                </>
              )
            )}
          </Stack>
        </Stack>
        {/* <Stack direction={"row"} gridTemplateColumns="1fr 2fr" gap={8} alignItems={"center"} pt={4} pb={4} borderBottomWidth={"1px"} borderBottomStyle="solid" borderBottomColor={"gray.200"}>
                    <Stack minWidth={"350px"}>
                        <Text fontWeight={"bold"} color={"gray.600"}>Domínio</Text>
                        <Text color={"gray.600"}>Dominio é o endereço que seus usuários acessam.</Text>
                    </Stack>
                    <Stack justifyContent={"center"} minWidth={"480px"}>
                        <InputGroup>
                            <InputLeftAddon children='https://app.seeksales.com.br/' background={"#EDF2F7"}/>
                            <Input 
                                type='tel' 
                                placeholder='Domínio' 
                                value={formik.values.domain}
                                onChange={formik.handleChange} 
                                background={"white"} 
                                width="100%"
                            />
                        </InputGroup>
                    </Stack>
                </Stack> */}
        <Stack
          direction={'row'}
          gridTemplateColumns="1fr 2fr"
          gap={8}
          alignItems={'center'}
          pt={4}
          pb={4}
          borderBottomWidth={'1px'}
          borderBottomStyle="solid"
          borderBottomColor={'gray.200'}
        >
          <Stack minWidth={'350px'}>
            <Text fontWeight={'bold'} color={'gray.600'}>
              Nome
            </Text>
          </Stack>
          <Stack justifyContent={'center'}>
            <Input
              type="text"
              name="first_name"
              placeholder="Nome"
              background={'white'}
              width="100%"
              minWidth={'480px'}
              value={formik.values.first_name}
              onChange={formik.handleChange}
            />
          </Stack>
        </Stack>
        <Stack
          direction={'row'}
          gridTemplateColumns="1fr 2fr"
          gap={8}
          alignItems={'center'}
          pt={4}
          pb={4}
          borderBottomWidth={'1px'}
          borderBottomStyle="solid"
          borderBottomColor={'gray.200'}
        >
          <Stack minWidth={'350px'}>
            <Text fontWeight={'bold'} color={'gray.600'}>
              Sobrenome
            </Text>
          </Stack>
          <Stack justifyContent={'center'}>
            <Input
              type="text"
              name="last_name"
              placeholder="Sobrenome"
              background={'white'}
              width="100%"
              minWidth={'480px'}
              value={formik.values.last_name}
              onChange={formik.handleChange}
            />
          </Stack>
        </Stack>
        <Stack
          direction={'row'}
          gridTemplateColumns="1fr 2fr"
          gap={8}
          alignItems={'center'}
          pt={4}
          pb={4}
          borderBottomWidth={'1px'}
          borderBottomStyle="solid"
          borderBottomColor={'gray.200'}
        >
          <Stack minWidth={'350px'}>
            <Text fontWeight={'bold'} color={'gray.600'}>
              Email
            </Text>
          </Stack>
          <Stack justifyContent={'center'}>
            <Input
              type="email"
              name="email"
              placeholder="Email"
              background={'white'}
              width="100%"
              minWidth={'480px'}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </Stack>
        </Stack>
        <Stack
          direction={'row'}
          gridTemplateColumns="1fr 2fr"
          gap={8}
          alignItems={'center'}
          pt={4}
          pb={4}
          borderBottomWidth={'1px'}
          borderBottomStyle="solid"
          borderBottomColor={'gray.200'}
        >
          <Stack minWidth={'350px'}>
            <Text fontWeight={'bold'} color={'gray.600'}>
              Senha
            </Text>
          </Stack>
          <Stack justifyContent={'center'}>
            <Input
              type="password"
              name="password"
              placeholder="Senha"
              background={'white'}
              width="100%"
              minWidth={'480px'}
              value={formik.values.password}
              onChange={formik.handleChange}
            />
          </Stack>
        </Stack>
        <Stack
          direction={'row'}
          gridTemplateColumns="1fr 2fr"
          gap={8}
          alignItems={'center'}
          pt={4}
          pb={4}
          borderBottomWidth={'1px'}
          borderBottomStyle="solid"
          borderBottomColor={'gray.200'}
        >
          <Stack minWidth={'350px'}>
            <Text fontWeight={'bold'} color={'gray.600'}>
              Telefone
            </Text>
          </Stack>
          <Stack justifyContent={'center'}>
            <Input
              name="phone"
              placeholder="Telefone"
              background={'white'}
              width="100%"
              minWidth={'480px'}
              value={formik.values.phone}
              onChange={formik.handleChange}
            />
          </Stack>
        </Stack>
        <Stack alignItems={'flex-end'} pt={4}>
          <Button
            onClick={formik.submitForm}
            ml={4}
            bgColor={'brand.100'}
            color={'white'}
            _hover={{
              bg: 'brand.100',
              color: 'white',
              filter: 'brightness(.8)',
            }}
            disabled={
              JSON.stringify(initialValues) === JSON.stringify(formik.values)
            }
          >
            Atualizar alterações
          </Button>
        </Stack>
      </form>
    </Stack>
  )
}
