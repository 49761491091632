import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { api } from '../../../../../utils/api'
import { Timeline } from '../../../../../components/Timeline'
import {
  Box,
  Button,
  Spinner,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { CreateActivityLead } from '../../CreateActivityLead'

import { IHistory } from '../../../../../hooks/useLeads'
import { queryClient } from '../../../../../hooks'
import { formatDate } from '../../../../../utils/formatDate'

export function Historics() {
  const { id } = useParams() as { id: string }
  const dialogCreateActivity = useDisclosure()

  const { data, isLoading } = useQuery(['historics', id], async () => {
    const response = await api.get<IHistory[]>(`/leads/${id}/historic`)
    return response.data
  })

  async function handleNewHistoric(historic: IHistory) {
    // const date = formatDate(historic.created_at)
    await queryClient.prefetchQuery(['historics', id])
    // const newHistoric = {
    //   ...historic,
    //   created_at: date,
    // }
  }

  const historics = data
    ? data.map((history) => ({
        ...history,
        created_at: formatDate(history.created_at),
      }))
    : []

  if (isLoading) {
    return (
      <VStack flex={1} alignItems="center">
        <Spinner color="blue.500" />
      </VStack>
    )
  }

  return (
    <>
      <Stack display={'flex'} alignItems="end">
        <Button onClick={dialogCreateActivity.onOpen} colorScheme={'blue'}>
          Registrar atividade
        </Button>
      </Stack>
      <CreateActivityLead
        idLead={id}
        onClose={dialogCreateActivity.onClose}
        isOpen={dialogCreateActivity.isOpen}
        onCreateNewActivity={(historic) => handleNewHistoric(historic)}
      />
      {isLoading ? (
        <Spinner color="blue.500" />
      ) : data && data.length > 0 ? (
        <Timeline data={historics} />
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg="gray.200"
          p={8}
          mt={6}
          rounded="md"
        >
          <Text>Nenhuma atividade registrada</Text>
        </Box>
      )}
    </>
  )
}
