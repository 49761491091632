import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react'
import { Input } from '../../../../components/Input'
import { api } from '../../../../utils/api'
import { IContacts } from '../../../Dashboard'

interface Props {
  isOpen?: boolean
  onClose: () => void
  onRegisterContact: (contact: IContacts) => void
  leadId: string
}

export function CreateNewContact({
  onClose,
  isOpen = false,
  leadId,
  onRegisterContact,
}: Props) {
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  })

  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      office: '',
      phone: '',
      lead_id: '',
    },
    onSubmit: async (values) => {
      const data = {
        ...values,
        lead_id: leadId,
      }

      try {
        setIsLoading(true)
        const schema = Yup.object({
          first_name: Yup.string().required('Nome é obrigatório'),
          last_name: Yup.string().required('Sobrenome é obrigatório'),
          email: Yup.string().email(),
          office: Yup.string(),
          phone: Yup.string(),
          lead_id: Yup.string(),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        const response = await api.post(`/contacts`, data)

        onRegisterContact(response.data)
        onClose()

        formik.resetForm()

        toast({
          title: 'Contato cadastrado com sucesso!',
          status: 'success',
        })
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          return toast({
            title: 'Dados obrigatórios não preenchidos',
            description: 'Verifique os dados incompletos.',
            status: 'error',
          })
        }

        toast({
          title: 'Ops, houve um error inesperado',
          status: 'error',
        })
      } finally {
        setIsLoading(false)
      }
    },
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent>
        <ModalHeader background={'brand.100'} color="white">
          Criar novo contato
        </ModalHeader>
        <ModalCloseButton color={'gray.200'} />

        <ModalBody>
          <form onSubmit={formik.handleSubmit}>
            <Stack direction={'row'} gap={4} mb={4}>
              <Input
                prompt="Nome"
                name="first_name"
                onChange={formik.handleChange}
                value={formik.values.first_name}
                placeholder="Nome"
              />
              <Input
                prompt="Sobrenome"
                name="last_name"
                onChange={formik.handleChange}
                value={formik.values.last_name}
                placeholder="Sobrenome"
              />
            </Stack>

            <Stack direction={'row'} gap={4} mb={4}>
              <Input
                prompt="Cargo"
                name="office"
                onChange={formik.handleChange}
                value={formik.values.office}
                placeholder="Cargo"
              />
              <Input
                prompt="Telefone"
                name="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                placeholder="Telefone"
              />
            </Stack>

            <Input
              prompt="E-mail"
              name="email"
              placeholder="E-mail"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Fechar</Button>
          <Button
            onClick={formik.submitForm}
            ml={4}
            bgColor={'brand.100'}
            color={'white'}
            _hover={{
              bg: 'brand.100',
              color: 'white',
              filter: 'brightness(.8)',
            }}
            isLoading={isLoading}
          >
            Criar contato
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
