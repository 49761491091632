import { useState } from 'react'
import { Avatar, Button, Stack, Text, useToast } from '@chakra-ui/react'
import { FiPhoneCall } from 'react-icons/fi'
import { useTheme } from 'styled-components'
import { IContacts } from '../../pages/Dashboard'
import { api } from '../../utils/api'
import { phoneFormatter } from '../../utils/formatter'
import axios from 'axios'

interface Props {
  contact: IContacts
}
export function Contact({ contact }: Props) {
  const { colors } = useTheme()
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  })
  const [isCalling, setIsCalling] = useState(false)

  async function handleCreateCall() {
    try {
      setIsCalling(true)

      await api.post('/calls', {
        lead_id: contact.lead_id,
        contact_id: contact.id,
      })

      toast({
        title: 'realizando chamada, verifique seu telefone',
        status: 'info',
      })
      // alert('realizando chamada, verifique seu telefone')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return toast({
          title: error.response?.data.message,
          status: 'error',
        })
      }
      return toast({
        title: 'Houve um erro ao efetuar a chamada',
        description: 'Tente novamente mais tarde',
        status: 'error',
      })
    } finally {
      setIsCalling(false)
    }
  }
  return (
    <Stack
      direction={'row'}
      mb={4}
      pb={4}
      borderBottomWidth="inherit"
      // cursor={'pointer'}
    >
      <Avatar name={`${contact.first_name} ${contact.last_name}`} />
      <Stack flex={1}>
        <Text
          fontSize={'md'}
          color={'brand.300'}
          fontWeight="bold"
        >{`${contact.first_name} ${contact.last_name}`}</Text>
        <Text fontSize={'12px'} color={'brand.600'}>
          {contact.office}
        </Text>
        <Text fontSize={'12px'} color={'brand.600'}>
          {contact.email}
        </Text>
        <Text fontSize={'12px'} color={'brand.600'}>
          {contact.phone && phoneFormatter(contact.phone)}
        </Text>
      </Stack>
      <Stack>
        <Button
          variant="unstyled"
          onClick={handleCreateCall}
          isLoading={isCalling}
        >
          <FiPhoneCall size={16} color={colors.brand[100]} />
        </Button>
      </Stack>
    </Stack>
  )
}
