import { useState } from 'react'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useToast,
} from '@chakra-ui/react'
// import { Editor } from '../../../components/Editor';
import { Editor } from '../../../../components/EditorDraft'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { api } from '../../../../utils/api'
import { useAuth } from '../../../../hooks/Auth'
import { IHistory } from '../../../../hooks/useLeads'

interface Props {
  idLead: string
  onClose: () => void
  onCreateNewActivity: (historic: IHistory) => void
  isOpen: boolean
}
export function CreateActivityLead({
  idLead,
  onClose,
  onCreateNewActivity,
  isOpen = false,
}: Props) {
  const { user } = useAuth()

  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  })

  const [content, setContent] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      title: '',
      type: '',
    },
    onSubmit: async (values) => {
      if (!user.tenant_id) {
        return toast({
          title: 'Somente colaboradores podem efetuar registros para leads.',
          status: 'warning',
        })
      }

      const data = {
        ...values,
        content,
        owner_id: user.id,
        lead_id: idLead,
      }
      try {
        setIsLoading(true)

        const schema = Yup.object({
          title: Yup.string().required(),
          type: Yup.string().required(),
          content: Yup.string().required(),
          owner_id: Yup.string().required(),
          lead_id: Yup.string().required(),
        })

        await schema.validate(data)

        const response = await api.post(`/leads/${idLead}/historic`, data)
        const newActivity = {
          ...response.data,
          owner: user,
        }
        onCreateNewActivity(newActivity)
        toast({
          title: 'Atividade registrada com sucesso',
          status: 'success',
        })

        onClose()
        formik.resetForm()
      } catch (error) {
        toast({
          title: 'Houve um erro, tente mais tarde.',
          status: 'error',
        })
      } finally {
        setIsLoading(false)
      }
    },
  })
  return (
    <Modal onClose={onClose} size="xl" isOpen={isOpen}>
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent>
        <ModalHeader background={'brand.100'} color="white">
          Registrar atividade
        </ModalHeader>
        <ModalCloseButton color={'gray.200'} />
        <ModalBody pt={4}>
          <form onSubmit={formik.handleSubmit}>
            <Input
              name="title"
              placeholder="Título da atividade"
              onChange={formik.handleChange}
              value={formik.values.title}
            />
            <Select
              name="type"
              placeholder="Selecione o tipo"
              onChange={formik.handleChange}
              value={formik.values.type}
              mt={4}
              mb={4}
            >
              <option value="activity">Atividade</option>
              <option value="contact">Ligação</option>
              <option value="notes">Observação</option>
            </Select>
            <Editor onChange={setContent} />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            onClick={formik.submitForm}
            bgColor={'brand.100'}
            color={'white'}
            ml={4}
            _hover={{
              bg: 'brand.100',
              color: 'white',
              filter: 'brightness(0.8)',
            }}
            isLoading={isLoading}
            disabled={!user.tenant_id}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
